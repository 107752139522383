import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('userId');

    const logoutUser = async () => {
      try {
        await fetch('https://clubedodinheiro.com.br/api/logout', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId }),
        });

        // Remove os itens do localStorage após o logout ser bem-sucedido
        localStorage.removeItem('token');
        localStorage.removeItem('userDetails');
        localStorage.removeItem('userId'); // Remove também o ID do usuário

        navigate('/'); // Redireciona para a página de login
      } catch (error) {
        console.error('Erro ao realizar logout:', error);
        // Exibe uma mensagem de erro ou trata o erro conforme necessário
      }
    };

    logoutUser();
  }, [navigate]);

  return (
    <div>
      <h2>Logging out...</h2>
    </div>
  );
};

export default Logout;
