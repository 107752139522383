import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/CartoesCreditoTable.css';

const CartoesCreditoTable = ({ userId }) => {
    const [cartoes, setCartoes] = useState([]);
    const [nivelUsuario, setNivelUsuario] = useState(1); // Valor padrão como 1
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId) {
            console.error('User ID não fornecido');
            return;
        }

        // Função para buscar o nível do usuário
        const fetchUserLevel = async () => {
            try {
                const response = await fetch(`/api/users_nivel/${userId}`);
                const data = await response.json();
                if (response.ok) {
                    setNivelUsuario(data.nivel); // Armazena o nível do usuário
                } else {
                    console.error('Erro ao buscar o nível do usuário:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar o nível do usuário:', error);
            }
        };

        // Função para buscar os cartões de crédito
        const fetchCartoes = async () => {
            try {
                const response = await fetch(`/api/users/${userId}/cartoes-detalhes`);
                const data = await response.json();
                if (response.ok) {
                    setCartoes(data.cartoes || []);
                } else {
                    console.error('Erro ao buscar os cartões de crédito:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar os cartões de crédito:', error);
            }
        };

        fetchUserLevel();
        fetchCartoes();
    }, [userId]);

    const handleNavigateToCartoes = () => {
        navigate('/cartoes');
    };

    const formatCurrency = (value, currency) => {
        try {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(value);
        } catch (e) {
            console.error('Invalid currency code:', currency);
            return value;
        }
    };

    // Função para extrair apenas o dia de uma data
    const extractDay = (dateString) => {
        const date = new Date(dateString);
        return date.getDate(); // Retorna apenas o dia da data
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="cartoesCreditoTableCard">
            <div className="card-header">
                Resumo dos Cartões de Crédito
                <button onClick={handleNavigateToCartoes} className="btn-ver-cartoes">
                    Ver Detalhes
                </button>
            </div>
            <div className="card-content">
                {nivelUsuario === 1 && cartoes.length > 1 && (
                    <div className="message-overlay">
                        <p>Para ver mais cartões, assine um plano.</p>
                    </div>
                )}
                <table className={`cartoes-table ${nivelUsuario === 1 && cartoes.length > 1 ? 'blurred' : ''}`}>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Limite</th>
                            <th>Limite Disponível</th>
                            <th>Fatura</th>
                            <th>Dia de Vencimento</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cartoes.map((cartao, index) => (
                            <tr key={index} className={index >= 1 && nivelUsuario === 1 ? 'embasado' : ''}>
                                <td>{cartao.nome}</td>
                                <td>{formatCurrency(cartao.limite, cartao.moeda)}</td>
                                <td>{formatCurrency(cartao.saldo_disponivel, cartao.moeda)}</td>
                                <td>{formatCurrency(cartao.limite - cartao.saldo_disponivel, cartao.moeda)}</td>
                                <td className="centralizar-texto">{extractDay(cartao.dia_vencimento)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
    );
};

export default CartoesCreditoTable;
