import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import '../../assets/styles/main.css'; // Importa o CSS global
import './ChangePassword.css';


const ResetPasswordPage = () => {
  const { hash } = useParams(); // Obtenha o hash da URL
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Estado para mostrar/ocultar a senha
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/reset-password-confirm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, hash }),
      });
  
      if (response.ok) {
        toast.success('Senha redefinida com sucesso! Redirecionando...');
        setTimeout(() => {
          navigate('/login'); // Redireciona para a página de login após 3 segundos
        }, 6000); // 5 segundos
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Erro ao redefinir a senha.');
      }
    } catch (error) {
      console.error('Erro ao realizar a requisição:', error);
      toast.error('Erro ao realizar a requisição: ' + error.message);
    }
  };
  

  const toggleShowPassword = () => {
    setShowPassword(!showPassword); // Alterna entre mostrar e ocultar a senha
  };

  return (
    <>
    <header>
    <h1>Redefinir sua senha</h1>
  </header>
    <main className="reset-password-container">
      <div className="reset-password-box">
        <ToastContainer />
        <h2>Redefinir sua senha</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Confirme seu Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              placeholder="Digite seu email" 
              required 
              value={email}
              onChange={(e) => setEmail(e.target.value)} 
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Nova Senha</label>
            <div className="password-input-wrapper">
              <input 
                type={showPassword ? 'text' : 'password'} 
                id="password" 
                name="password" 
                placeholder="Digite sua nova senha" 
                required 
                value={password}
                onChange={(e) => setPassword(e.target.value)} 
              />
              <span onClick={toggleShowPassword} className="password-toggle-icon">
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>
          </div>
          <button type="submit" className="login-button">Redefinir Senha</button>
        </form>
      </div>
    </main>
    </>
  );
};

export default ResetPasswordPage;
