import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/CarteirasTable.css';

const CarteirasTable = ({ userId }) => {
    const [carteiras, setCarteiras] = useState([]);
    const [nivelUsuario, setNivelUsuario] = useState(1); // Valor padrão como 1
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId) {
            console.error('User ID não fornecido');
            return;
        }

        // Função para buscar o nível do usuário
        const fetchUserLevel = async () => {
            try {
                const response = await fetch(`/api/users_nivel/${userId}`);
                const data = await response.json();
                if (response.ok) {
                    setNivelUsuario(data.nivel); // Armazena o nível do usuário
                } else {
                    console.error('Erro ao buscar o nível do usuário:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar o nível do usuário:', error);
            }
        };

        // Função para buscar as carteiras
        const fetchCarteiras = async () => {
            try {
                const response = await fetch(`/api/users/${userId}/carteiras-detalhes`);
                const data = await response.json();
                if (response.ok) {
                    setCarteiras(data.carteiras || []);
                } else {
                    console.error('Erro ao buscar as carteiras:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar as carteiras:', error);
            }
        };

        fetchUserLevel();
        fetchCarteiras();
    }, [userId]);

    const handleNavigateToCarteiras = () => {
        navigate('/carteiras');
    };

    const formatCurrency = (value, currency) => {
        try {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(value);
        } catch (e) {
            console.error('Invalid currency code:', currency);
            return value;
        }
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="carteirasTableCard">
            <div className="card-header">
                Resumo das Carteiras
                <button onClick={handleNavigateToCarteiras} className="btn-ver-carteiras">
                    Ver Detalhes
                </button>
            </div>
            <div className="card-content">
                {nivelUsuario === 1 && carteiras.length > 1 && (
                    <div className="message-overlay">
                        <p>Para ver mais carteiras, assine um plano.</p>
                    </div>
                )}
                <table className={`carteiras-table ${nivelUsuario === 1 && carteiras.length > 1 ? 'blurred' : ''}`}>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Saldo</th>
                            <th>Tipo</th>
                            <th>Moeda</th>
                        </tr>
                    </thead>
                    <tbody>
                        {carteiras.map((carteira, index) => (
                            <tr key={index} className={index >= 1 && nivelUsuario === 1 ? 'embasado' : ''}>
                                <td>{carteira.nome}</td>
                                {/* Usando saldo_atual como saldo a ser exibido */}
                                <td>{formatCurrency(carteira.saldo_atual, carteira.moeda)}</td>
                                {/* Mudando tipo_carteira para tipo */}
                                <td>{carteira.tipo}</td>
                                <td className="centralizar-texto">{carteira.moeda}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CarteirasTable;
