import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Adicione esta linha
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './categorias.css';
import { 
    faEdit, 
    faTrash, 
    faUtensils, 
    faCar, 
    faShoppingCart, 
    faHome, 
    faPiggyBank, 
    faFileInvoiceDollar, 
    faQuestionCircle,
    faBook,
    faHeartbeat,
    faPills,
    faPlane,
    faFilm,
    faTshirt,
    faShoePrints,
    faGem,
    faMobileAlt,
    faWifi,
    faPhone,
    faBolt,
    faTint,
    faBurn,
    faTv,
    faNewspaper,
    faFileInvoice,
    faCarAlt,
    faShieldAlt,
    faCarCrash,
    faTools,
    faBroom,
    faBaby,
    faSoap,
    faSpa,
    faParking,
    faGavel,
    faBriefcase,
    faMoneyCheckAlt,
    faCreditCard,
    faHandHoldingUsd,
    faHandsHelping,
    faGift,
    faSmile,
    faCalendarAlt,
    faPaw,
    faChild,
    faGraduationCap,
    faLanguage,
    faLock,
    faBuilding,
    faCut,
    faDumbbell,
    faRunning,
    faLaptopCode,
    faHardHat,
    faSeedling,
    faBlender,
    faWrench,
    faCode,
    faMoneyBillWave,
    faPlaneDeparture,
    faIdBadge,
    faCertificate,
    faChalkboardTeacher,
    faBabyCarriage,
    faHandshake,
    faBusAlt,
    faHandHoldingHeart
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Categorias = () => {
    const [categoriasReceita, setCategoriasReceita] = useState([]);
    const [categoriasDespesa, setCategoriasDespesa] = useState([]);
    const [newCategoria, setNewCategoria] = useState('');
    const [tipoCategoria, setTipoCategoria] = useState('Receita');
    const [modalVisible, setModalVisible] = useState(false);
    const [editCategoria, setEditCategoria] = useState(null);
    const [editNomeCategoria, setEditNomeCategoria] = useState('');
    const [editTipoCategoria, setEditTipoCategoria] = useState('');
    const [categoriaParaDeletar, setCategoriaParaDeletar] = useState(null); // Estado para a categoria a ser deletada
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para modal de confirmação
    const userId = localStorage.getItem('userId');

    // Mapeamento de ícones por nome de categoria e suas variações (sem acentos)
    const categoryIconMap = {
        // Categorias de Receitas
        salario: faFileInvoiceDollar,
        extra: faFileInvoiceDollar,
        pagamento: faFileInvoiceDollar,
        'renda fixa': faFileInvoiceDollar,
        ordenado: faFileInvoiceDollar,
        freelancer: faFileInvoiceDollar,
        'trabalho independente': faFileInvoiceDollar,
        projeto: faFileInvoiceDollar,
        consultoria: faFileInvoiceDollar,
        investimentos: faPiggyBank,
        aplicacoes: faPiggyBank,
        acoes: faPiggyBank,
        'rendimento financeiro': faPiggyBank,
        aluguel: faHome,
        locacao: faHome,
        'renda de imoveis': faHome,
        arrendamento: faHome,
        premios: faFileInvoiceDollar,
        concurso: faFileInvoiceDollar,
        loteria: faFileInvoiceDollar,
        recompensa: faFileInvoiceDollar,
        vendas: faShoppingCart,
        comercializacao: faShoppingCart,
        'venda de ativos': faShoppingCart,
        liquidacao: faShoppingCart,
        heranca: faFileInvoiceDollar,
        patrimonio: faFileInvoiceDollar,
        'bens herdados': faFileInvoiceDollar,
        legado: faFileInvoiceDollar,
        'rendimentos bancarios': faPiggyBank,
        juros: faPiggyBank,
        rentabilidade: faPiggyBank,
        poupanca: faPiggyBank,
        reembolso: faFileInvoiceDollar,
        restituicao: faFileInvoiceDollar,
        devolucao: faFileInvoiceDollar,
        ressarcimento: faFileInvoiceDollar,
        dividendo: faFileInvoiceDollar,
        lucros: faFileInvoiceDollar,
        'partilha de ganhos': faFileInvoiceDollar,
        'retorno de investimentos': faFileInvoiceDollar,
        bonificacoes: faFileInvoiceDollar,
        bonus: faFileInvoiceDollar,
        gratificacao: faFileInvoiceDollar,
        'recompensa de desempenho': faFileInvoiceDollar,
        patrocinios: faFileInvoiceDollar,
        'apoio financeiro': faFileInvoiceDollar,
        subvencao: faFileInvoiceDollar,
        parceria: faFileInvoiceDollar,
        aposentadoria: faFileInvoiceDollar,
        pensao: faFileInvoiceDollar,
        'beneficio previdenciario': faFileInvoiceDollar,
        'renda vitalicia': faFileInvoiceDollar,
        'venda de produtos': faShoppingCart,
        'comercializacao de bens': faShoppingCart,
        'produto vendido': faShoppingCart,
        'lucro de venda': faShoppingCart,
        'venda de servicos': faShoppingCart,
        'prestacao de servico': faShoppingCart,
        'trabalho contratado': faShoppingCart,
        'servicos terceirizados': faShoppingCart,
        royalty: faFileInvoiceDollar,
        licenciamento: faFileInvoiceDollar,
        'direito autoral': faFileInvoiceDollar,
        comissao: faFileInvoiceDollar,
        contribuicoes: faFileInvoiceDollar,
        doacoes: faFileInvoiceDollar,
        colaboracao: faFileInvoiceDollar,
        'apoio financeiro': faFileInvoiceDollar,
        'ganhos com criptomoedas': faFileInvoiceDollar,
        'rendimento com bitcoin': faFileInvoiceDollar,
        'lucro com moedas digitais': faFileInvoiceDollar,
        criptoativos: faFileInvoiceDollar,

        // Categorias de Despesas e Suas Variações
        alimentacao: faUtensils,
        comida: faUtensils,
        lanche: faUtensils,
        restaurante: faUtensils,
        refeicao: faUtensils,
        'fast food': faUtensils,

        supermercado: faShoppingCart,
        'compras de mercado': faShoppingCart,
        mercearia: faShoppingCart,
        feira: faShoppingCart,
        alimentos: faShoppingCart,

        moradia: faHome,
        casa: faHome,
        apartamento: faHome,
        lar: faHome,
        residencia: faHome,
        imovel: faHome,

        aluguel: faHome,
        locacao: faHome,
        'pagamento de aluguel': faHome,
        arrendamento: faHome,

        transporte: faCar,
        locomocao: faCar,
        viagem: faCar,
        deslocamento: faCar,

        combustivel: faCar,
        gasolina: faCar,
        diesel: faCar,
        etanol: faCar,
        abastecimento: faCar,

        'manutencao de veiculo': faCar,
        'conserto de carro': faCar,
        revisao: faCar,
        'troca de oleo': faCar,
        oficina: faCar,

        'transporte publico': faCar,
        onibus: faCar,
        metro: faCar,
        trem: faCar,
        passagem: faCar,

        educacao: faBook,
        escola: faBook,
        faculdade: faBook,
        ensino: faBook,
        estudo: faBook,
        curso: faBook,

        'mensalidade escolar': faBook,
        colegio: faBook,
        'pagamento de escola': faBook,
        universidade: faBook,

        'cursos e treinamentos': faBook,
        aula: faBook,
        formacao: faBook,
        capacitacao: faBook,
        aprendizado: faBook,

        'material escolar': faBook,
        livros: faBook,
        cadernos: faBook,
        lapis: faBook,
        'material didatico': faBook,

        saude: faHeartbeat,
        medico: faHeartbeat,
        tratamento: faHeartbeat,
        'bem-estar': faHeartbeat,
        hospital: faHeartbeat,

        medicamentos: faPills,
        remedios: faPills,
        farmacia: faPills,
        drogaria: faPills,

        'plano de saude': faHeartbeat,
        convenio: faHeartbeat,
        'seguro saude': faHeartbeat,
        'assistencia medica': faHeartbeat,

        'exames medicos': faHeartbeat,
        'check-up': faHeartbeat,
        diagnostico: faHeartbeat,
        consulta: faHeartbeat,

        lazer: faSmile,
        diversao: faSmile,
        entretenimento: faSmile,
        passeio: faSmile,

        viagens: faPlane,
        turismo: faPlane,
        ferias: faPlane,
        passeio: faPlane,
        excursao: faPlane,

        cinema: faFilm,
        filmes: faFilm,
        sessao: faFilm,
        'lazer audiovisual': faFilm,

        vestuario: faTshirt,
        roupas: faTshirt,
        moda: faTshirt,
        trajes: faTshirt,
        'roupa casual': faTshirt,

        calcados: faShoePrints,
        sapatos: faShoePrints,
        tenis: faShoePrints,
        botas: faShoePrints,
        sandalias: faShoePrints,

        acessorios: faGem,
        relogios: faGem,
        bolsas: faGem,
        joias: faGem,

        eletronicos: faMobileAlt,
        gadgets: faMobileAlt,
        dispositivos: faMobileAlt,
        aparelhos: faMobileAlt,

        internet: faWifi,
        'wi-fi': faWifi,
        'banda larga': faWifi,
        conexao: faWifi,

        telefone: faPhone,
        celular: faPhone,
        'telefone fixo': faPhone,
        'contas de telefone': faPhone,

        'energia eletrica': faBolt,
        luz: faBolt,
        'conta de eletricidade': faBolt,
        'fornecimento de energia': faBolt,

        agua: faTint,
        saneamento: faTint,
        'fornecimento de agua': faTint,
        'conta de agua': faTint,

        gas: faBurn,
        'energia a gas': faBurn,
        botijao: faBurn,
        'fornecimento de gas': faBurn,

        'servicos de streaming': faTv,
        netflix: faTv,
        'assinatura digital': faTv,
        'video sob demanda': faTv,

        'tv a cabo': faTv,
        'televisao por assinatura': faTv,
        'pacote de canais': faTv,
        'streaming de tv': faTv,

        'assinaturas digitais': faNewspaper,
        'clube de assinatura': faNewspaper,
        'servicos online': faNewspaper,
        'planos recorrentes': faNewspaper,

        impostos: faFileInvoice,
        taxas: faFileInvoice,
        tributos: faFileInvoice,
        contribuicoes: faFileInvoice,

        iptu: faFileInvoice,
        'imposto sobre imovel': faFileInvoice,
        'taxa de terreno': faFileInvoice,
        'imposto residencial': faFileInvoice,

        ipva: faCarAlt,
        'imposto de veiculo': faCarAlt,
        'contribuicao veicular': faCarAlt,
        'taxa automotiva': faCarAlt,

        seguros: faShieldAlt,
        protecao: faShieldAlt,
        apolice: faShieldAlt,
        'plano de seguro': faShieldAlt,

        'seguro veicular': faCarCrash,
        'cobertura de carro': faCarCrash,
        'protecao automotiva': faCarCrash,
        'apolice veicular': faCarCrash,

        'seguro residencial': faHome,
        'protecao de casa': faHome,
        'apolice imobiliaria': faHome,
        'cobertura domiciliar': faHome,

        'seguro de vida': faHeartbeat,
        'protecao pessoal': faHeartbeat,
        'plano de vida': faHeartbeat,
        'apolice de vida': faHeartbeat,

        'manutencao residencial': faTools,
        reforma: faTools,
        reparos: faTools,
        'conserto de casa': faTools,

        'servicos domesticos': faBroom,
        limpeza: faBroom,
        faxina: faBroom,
        jardinagem: faSeedling,

        baba: faBaby,
        'cuidado infantil': faBaby,
        'assistente de criancas': faBaby,
        cuidadora: faBaby,

        'produtos de higiene': faSoap,
        'limpeza pessoal': faSoap,
        'cuidados corporais': faSoap,
        'produtos de beleza': faSoap,

        beleza: faSpa,
        cabelo: faSpa,
        maquiagem: faSpa,
        estetica: faSpa,
        manicure: faSpa,

        estacionamento: faParking,
        vaga: faParking,
        garagem: faParking,
        'local de estacionar': faParking,

        multas: faGavel,
        penalidade: faGavel,
        infracao: faGavel,
        sancao: faGavel,

        consultoria: faBriefcase,
        assessoria: faBriefcase,
        aconselhamento: faBriefcase,
        consultor: faBriefcase,

        dividas: faMoneyCheckAlt,
        emprestimos: faMoneyCheckAlt,
        financiamentos: faMoneyCheckAlt,
        parcelamentos: faMoneyCheckAlt,

        'cartoes de credito': faCreditCard,
        'pagamento de fatura': faCreditCard,
        'divida de cartao': faCreditCard,
        'credito rotativo': faCreditCard,
        cartao: faCreditCard,

        mensalidades: faFileInvoiceDollar,
        assinatura: faFileInvoiceDollar,
        'pagamento recorrente': faFileInvoiceDollar,
        'cobranca mensal': faFileInvoiceDollar,

        emprestimos: faHandHoldingUsd,
        'credito pessoal': faHandHoldingUsd,
        financiamento: faHandHoldingUsd,
        divida: faHandHoldingUsd,

        'financiamento imobiliario': faHome,
        hipoteca: faHome,
        'credito para imoveis': faHome,
        'pagamento de casa': faHome,

        'taxas bancarias': faPiggyBank,
        'tarifas de conta': faPiggyBank,
        'custos bancarios': faPiggyBank,
        'servicos bancarios': faPiggyBank,

        'impostos de renda': faFileInvoiceDollar,
        ir: faFileInvoiceDollar,
        'declaracao de imposto': faFileInvoiceDollar,
        'contribuicao federal': faFileInvoiceDollar,

        'despesas legais': faGavel,
        'custas judiciais': faGavel,
        advogado: faGavel,
        'honorarios legais': faGavel,

        doacoes: faHandsHelping,
        caridade: faHandsHelping,
        filantropia: faHandsHelping,
        'contribuicao beneficente': faHandsHelping,

        presentes: faGift,
        lembrancas: faGift,
        mimos: faGift,
        'compras de presentes': faGift,

        recreacao: faSmile,
        parques: faSmile,
        diversao: faSmile,
        'atividades de lazer': faSmile,

        eventos: faCalendarAlt,
        festas: faCalendarAlt,
        'ocasioes especiais': faCalendarAlt,
        cerimonias: faCalendarAlt,

        'animais de estimacao': faPaw,
        pets: faPaw,
        veterinario: faPaw,
        'alimentacao animal': faPaw,

        'consertos de equipamentos': faTools,
        'reparos tecnicos': faTools,
        'assistencia tecnica': faTools,
        manutencao: faTools,

        'produtos infantis': faBabyCarriage,
        brinquedos: faBabyCarriage,
        'roupas de crianca': faBabyCarriage,
        'produtos de bebe': faBabyCarriage,

        'educacao infantil': faChild,
        creche: faChild,
        'pre-escola': faChild,
        'jardim de infancia': faChild,

        'aulas particulares': faChalkboardTeacher,
        ensino: faChalkboardTeacher,
        'educacao privada': faChalkboardTeacher,
        tutor: faChalkboardTeacher,

        faculdade: faGraduationCap,
        universidade: faGraduationCap,
        'ensino superior': faGraduationCap,
        'curso universitario': faGraduationCap,

        'cursos de idiomas': faLanguage,
        'aulas de linguas': faLanguage,
        'ensino de linguas': faLanguage,
        'cursos internacionais': faLanguage,

        seguranca: faLock,
        alarmes: faLock,
        vigilancia: faLock,
        monitoramento: faLock,

        condominio: faBuilding,
        'taxa condominial': faBuilding,
        'residencia compartilhada': faBuilding,
        sindico: faBuilding,

        'reparos de eletrodomesticos': faTools,
        'conserto de geladeira': faTools,
        'assistencia de maquina': faTools,
        'reparo tecnico': faTools,

        'limpeza domestica': faBroom,
        faxina: faBroom,
        'limpeza de casa': faBroom,
        'servico de limpeza': faBroom,

        'despesas de viagem': faPlaneDeparture,
        'passagem aerea': faPlaneDeparture,
        hospedagem: faPlaneDeparture,
        'pacote de viagem': faPlaneDeparture,

        'licencas e permissoes': faIdBadge,
        licenciamento: faIdBadge,
        autorizacao: faIdBadge,
        registro: faIdBadge,

        'feiras e exposicoes': faCertificate,
        eventos: faCertificate,
        mostras: faCertificate,
        conferencias: faCertificate,

        cabeleireiro: faCut,
        'salao de beleza': faCut,
        'cortes de cabelo': faCut,
        'estetica capilar': faCut,

        academia: faDumbbell,
        'treinamento fisico': faDumbbell,
        'mensalidade de ginasio': faDumbbell,
        exercicios: faDumbbell,

        'cursos esportivos': faRunning,
        'aulas de natacao': faRunning,
        futebol: faRunning,
        judo: faRunning,

        'servicos de ti': faLaptopCode,
        'suporte tecnico': faLaptopCode,
        'consultoria de ti': faLaptopCode,
        'assistencia digital': faLaptopCode,

        construcao: faHardHat,
        obra: faHardHat,
        reforma: faHardHat,
        'construcao civil': faHardHat,

        jardinagem: faSeedling,
        'cuidado de plantas': faSeedling,
        paisagismo: faSeedling,
        'servicos de jardinagem': faSeedling,

        eletrodomesticos: faBlender,
        'aparelhos de cozinha': faBlender,
        geladeira: faBlender,
        'maquina de lavar': faBlender,

        ferramentas: faWrench,
        equipamentos: faWrench,
        'utensilios de reparos': faWrench,
        'ferramentas eletricas': faWrench,

        software: faCode,
        aplicativos: faCode,
        programas: faCode,
        'licenciamento digital': faCode,

        'taxas de servico': faMoneyBillWave,
        comissoes: faMoneyBillWave,
        'pagamento de servicos': faMoneyBillWave,
        'cobrancas extras': faMoneyBillWave,

        'refeicoes de trabalho': faUtensils,
        'jantares corporativos': faUtensils,
        'almoco de negocios': faUtensils,
        'alimentacao corporativa': faUtensils,

        uniformes: faTshirt,
        'roupas de trabalho': faTshirt,
        'trajes profissionais': faTshirt,
        'vestimenta corporativa': faTshirt        
    };


    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                closeEditModal();
                closeModal();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
    
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    const handleOverlayClick = (e) => {
        if (e.target.className === 'modal-overlay') {
            closeModal();
            closeEditModal();
        }
    };
    
    
    // Função para buscar categorias
    const fetchCategorias = async () => {
        try {
            const response = await axios.get(`/api/users/${userId}/categorias`);
            const { categorias } = response.data;

            // Separar categorias de Receita e Despesa
            const receitas = categorias.filter(cat => cat.tipo_categoria === 'Receita');
            const despesas = categorias.filter(cat => cat.tipo_categoria === 'Despesa');

            setCategoriasReceita(receitas);
            setCategoriasDespesa(despesas);
        } catch (error) {
            console.error('Erro ao buscar categorias:', error);
            toast.error('Erro ao carregar categorias.');
        }
    };

    useEffect(() => {
        fetchCategorias();
    }, [userId]);

        // Função para abrir o modal de edição
        const openEditModal = (categoria) => {
            setEditCategoria(categoria);
            setEditNomeCategoria(categoria.nome_categoria);
            setEditTipoCategoria(categoria.tipo_categoria);
            setModalVisible(true);
        };
    
        // Função para fechar o modal
        const closeEditModal = () => {
            setModalVisible(false);
            setEditCategoria(null);
        };


        // Função para abrir o modal de confirmação de deleção
        const openModal = (categoria) => {
            setCategoriaParaDeletar(categoria);
            setIsModalOpen(true);
        };

        // Função para fechar o modal de confirmação
        const closeModal = () => {
            setIsModalOpen(false);
            setCategoriaParaDeletar(null);
        };

        // Função para confirmar a exclusão da categoria
        const confirmDelete = async () => {
            if (!categoriaParaDeletar) return;

            try {
                const response = await axios.delete(`/api/users/${userId}/categorias/${categoriaParaDeletar.id_categoria}`);
                if (response.data.message === 'Categoria deletada com sucesso.') {
                    toast.success('Categoria deletada com sucesso!');
                    fetchCategorias(); // Atualizar a lista
                }
            } catch (error) {
                toast.error('Erro ao deletar categoria.');
            } finally {
                closeModal(); // Fechar modal
            }
        };

    // Função para adicionar uma nova categoria
    const handleAddCategoria = async () => {
        // Normalizando o nome da nova categoria para comparação
        const normalizedNewCategoria = newCategoria.trim().toLowerCase();

        // Verificar se a categoria já existe na lista de receitas ou despesas, com base no tipoCategoria
        if (tipoCategoria === 'Receita') {
            const categoriaExistenteReceita = categoriasReceita.some(categoria =>
                categoria.nome_categoria.trim().toLowerCase() === normalizedNewCategoria
            );

            if (categoriaExistenteReceita) {
                // Se a categoria já existir em Receitas, exibir uma mensagem de erro
                toast.error('Categoria já existe em Receitas!');
                return; // Não prosseguir com a criação
            }
        } else if (tipoCategoria === 'Despesa') {
            const categoriaExistenteDespesa = categoriasDespesa.some(categoria =>
                categoria.nome_categoria.trim().toLowerCase() === normalizedNewCategoria
            );

            if (categoriaExistenteDespesa) {
                // Se a categoria já existir em Despesas, exibir uma mensagem de erro
                toast.error('Categoria já existe em Despesas!');
                return; // Não prosseguir com a criação
            }
        }

        try {
            const response = await axios.post(`/api/users/${userId}/categorias`, {
                tipo_categoria: tipoCategoria,
                nome_categoria: newCategoria
            });

            if (response.data.message === 'Categoria adicionada com sucesso.') {
                toast.success('Categoria adicionada com sucesso!');
                setNewCategoria('');
                setTipoCategoria('Receita');
                fetchCategorias(); // Atualiza a lista de categorias
            }
        } catch (error) {
            toast.error('Erro ao adicionar categoria.');
        }
    };



// Função para deletar categoria
const handleDeleteCategoria = async (categoriaId, nomeCategoria) => {
    const confirmDelete = window.confirm(`Tem certeza que deseja deletar a categoria "${nomeCategoria}"?`);

    if (confirmDelete) {
        try {
            const response = await axios.delete(`/api/users/${userId}/categorias/${categoriaId}`);
            if (response.data.message === 'Categoria deletada com sucesso.') {
                toast.success('Categoria deletada com sucesso!');
                fetchCategorias();
            }
        } catch (error) {
            toast.error('Erro ao deletar categoria.');
        }
    }
};




    // Função para editar categoria
    const handleEditCategoria = async () => {
        try {
            const response = await axios.put(`/api/users/${userId}/categorias/${editCategoria.id_categoria}`, {
                nome_categoria: editNomeCategoria,
                tipo_categoria: editTipoCategoria
            });
            if (response.data.message === 'Categoria editada com sucesso.') {
                toast.success('Categoria editada com sucesso!');
                fetchCategorias();
                closeEditModal(); // Fecha o modal após a edição
            }
        } catch (error) {
            toast.error('Erro ao editar categoria.');
        }
    };

    // Função para obter o ícone da categoria
    const getCategoryIcon = (nomeCategoria) => {
        // Normaliza o nome para remover acentos e converter para minúsculas
        const normalizedCategoria = nomeCategoria
            .trim()
            .toLowerCase()
            .normalize('NFD') // Decompõe o texto em caracteres base e acentos
            .replace(/\p{Diacritic}/gu, ""); // Remove os acentos (diacríticos)

        // Faz o mapeamento usando o nome normalizado
        return categoryIconMap[normalizedCategoria] || faQuestionCircle;
    };


        


        return (
            <div className="categorias-container">
                <h2>Adicionar Nova Categorias</h2>
                <div className="add-categoria-form">
                    <input
                        type="text"
                        value={newCategoria}
                        onChange={(e) => setNewCategoria(e.target.value)}
                        placeholder="Nome da Categoria"
                    />
                    <select value={tipoCategoria} onChange={(e) => setTipoCategoria(e.target.value)}>
                        <option value="Receita">Receita</option>
                        <option value="Despesa">Despesa</option>
                    </select>
                    <button onClick={handleAddCategoria}>Adicionar Categoria</button>
                </div>
                <br></br> 
                <br></br>  
                <br></br>
                <div className="categoria-section">
                    <h3>Categorias de Receita</h3>
                    {categoriasReceita.length > 0 ? (
                        <ul className="categorias-list">
                            {categoriasReceita.map((categoria) => (
                                <li key={categoria.id_categoria}>
                                    <div className="categoria-text">
                                        <FontAwesomeIcon icon={getCategoryIcon(categoria.nome_categoria)} className="categoria-icon" />
                                        <span>{categoria.nome_categoria}</span>
                                    </div>
                                    <div className="icon-group">
                                        <button className="icon-btn icoeditar" onClick={() => openEditModal(categoria)}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button className="icon-btn" onClick={() => openModal(categoria)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="empty-message">Nenhuma categoria de receita encontrada.</p>
                    )}
                </div>
        
                <div className="categoria-section">
                    <h3>Categorias de Despesa</h3>
                    {categoriasDespesa.length > 0 ? (
                        <ul className="categorias-list">
                            {categoriasDespesa.map((categoria) => (
                                <li key={categoria.id_categoria}>
                                    <div className="categoria-text">
                                        <FontAwesomeIcon icon={getCategoryIcon(categoria.nome_categoria)} className="categoria-icon" />
                                        <span>{categoria.nome_categoria}</span>
                                    </div>
                                    <div className="icon-group">
                                        <button className="icon-btn icoeditar" onClick={() => openEditModal(categoria)}>
                                            <FontAwesomeIcon icon={faEdit} />
                                        </button>
                                        <button className="icon-btn" onClick={() => openModal(categoria)}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p className="empty-message">Nenhuma categoria de despesa encontrada.</p>
                    )}
                </div>
        
                {/* Modal de confirmação de deleção */}
                {isModalOpen && (
                    <div className="modal-overlay" onClick={handleOverlayClick}>
                        <div className="modal-content">
                            <h3>Confirmar Exclusão</h3>
                            <p>Tem certeza que deseja excluir a categoria "{categoriaParaDeletar?.nome_categoria}"?</p>
                            <div className="modal-actions">
                                <button className="btn-confirm" onClick={confirmDelete}>Excluir</button>
                                <button className="btn-cancel" onClick={closeModal}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                )}
        
                {/* Modal de Edição */}
                {modalVisible && (
                    <div className="modal-overlay" onClick={handleOverlayClick}>
                        <div className="modal-content">
                            <h3>Editar Categoria</h3>
                            <label>Nome da Categoria</label>
                            <input
                                type="text"
                                value={editNomeCategoria}
                                onChange={(e) => setEditNomeCategoria(e.target.value)}
                            />
                            <label>Tipo de Categoria</label>
                            <select value={editTipoCategoria} onChange={(e) => setEditTipoCategoria(e.target.value)}>
                                <option value="Receita">Receita</option>
                                <option value="Despesa">Despesa</option>
                            </select>
                            <div className="modal-actions">
                                <button onClick={handleEditCategoria}>Salvar</button>
                                <button onClick={closeEditModal}>Cancelar</button>
                            </div>
                        </div>
                    </div>
                )}
        
                <ToastContainer />
            </div>
        );
        
    };        

export default Categorias;
