import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId'); // Certifique-se de que o userId seja armazenado no localStorage após o login.
  const location = useLocation(); // Obter a localização atual da rota

  useEffect(() => {
    const checkUserStatus = async () => {
      if (token && userId) {
        try {
          const response = await fetch(`/api/users/${userId}/status`);
          const data = await response.json();

          if (data.status_conexao === 0) {
            // Se o status_conexao for 0, o usuário está desconectado
            setIsLoggedIn(false);
          } else {
            setIsLoggedIn(true); // Garantir que o usuário esteja conectado
            // Registrar o acesso do usuário
            registerUserAccess();
          }
        } catch (error) {
          console.error('Erro ao verificar o status de conexão do usuário:', error);
          setIsLoggedIn(false); // Em caso de erro, considerar o usuário como desconectado
        }
      } else {
        setIsLoggedIn(false);
      }
      setIsLoading(false);
    };

    const registerUserAccess = async () => {
      try {
        const response = await fetch('/api/register-access', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: userId,
            current_url: window.location.pathname,
          }),
        });

        if (!response.ok) {
          throw new Error('Erro ao registrar acesso');
        }
      } catch (error) {
        console.error('Erro ao registrar o acesso do usuário:', error);
      }
    };

    checkUserStatus(); // Verifica sempre que a rota muda

  }, [token, userId, location.pathname]); // O useEffect será executado sempre que a localização mudar

  if (isLoading) {
    return <div>Carregando...</div>; // Exibir algo enquanto verifica o status
  }

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
