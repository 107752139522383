import React, { useState, useEffect } from 'react';
import { FaPlus, FaMinus, FaArrowUp, FaArrowDown, FaExchangeAlt } from 'react-icons/fa';
import TransferenciaModal from '../../modals/TransferenciaModal';
import LancamentoModal from '../../modals/LancamentoModal';
import './floating-button.css';

const FloatingButton = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isReceitaModalOpen, setIsReceitaModalOpen] = useState(false);
  const [isDespesaModalOpen, setIsDespesaModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);

  const toggleMenu = (event) => {
    setIsMenuOpen(!isMenuOpen);
    event.stopPropagation();
  };

  const handleClickOutside = (event) => {
    if (!document.getElementById('fab-button').contains(event.target) &&
        !document.getElementById('fab-menu').contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const openModal = (type) => {
    if (type === 'receita') {
      setIsReceitaModalOpen(true);
    } else if (type === 'despesa') {
      setIsDespesaModalOpen(true);
    } else if (type === 'transferencia') {
      setIsTransferModalOpen(true);
    }
  };

  const closeModal = (type) => {
    if (type === 'receita') {
      setIsReceitaModalOpen(false);
    } else if (type === 'despesa') {
      setIsDespesaModalOpen(false);
    } else if (type === 'transferencia') {
      setIsTransferModalOpen(false);
    }
  };

  return (
    <div className="fab-container" id="fab-container">
      <button className="fab-button" id="fab-button" onClick={toggleMenu}>
        {isMenuOpen ? <FaMinus /> : <FaPlus />}
      </button>
      <div className={`fab-menu ${isMenuOpen ? 'show' : ''}`} id="fab-menu">
        <button className="tooltip" onClick={() => openModal('receita')} data-tooltip="Receita">
          <FaArrowUp />
        </button>
        <button className="tooltip" onClick={() => openModal('despesa')} data-tooltip="Despesa">
          <FaArrowDown />
        </button>
        <button className="tooltip" onClick={() => openModal('transferencia')} data-tooltip="Transferência">
          <FaExchangeAlt />
        </button>
      </div>

      {/* Modal para Nova Receita */}
      <LancamentoModal
        isOpen={isReceitaModalOpen}
        onClose={() => closeModal('receita')}
        tipoLancamentoInicial="Receita"
      />

      {/* Modal para Nova Despesa */}
      <LancamentoModal
        isOpen={isDespesaModalOpen}
        onClose={() => closeModal('despesa')}
        tipoLancamentoInicial="Despesa"
      />

      {/* Modal para Transferência */}
      <TransferenciaModal
        isOpen={isTransferModalOpen}
        onClose={() => closeModal('transferencia')}
      />
    </div>
  );
};

export default FloatingButton;
