import React, { useState, useEffect } from 'react';
import './LogsFilterModal.css';

const LogsFilterModal = ({ isOpen, onClose, userId }) => {
  const [isClosing, setIsClosing] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('logs-filter-modal')) {
      handleClose();
    }
  };

  const handleGeneratePDF = async () => {
    try {
      const response = await fetch(`/api/logs?userId=${userId}&start=${startDate}&end=${endDate}`);
      const blob = await response.blob();
      const filename = `log_${userId}_${startDate}_to_${endDate}.pdf`;
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();
    } catch (error) {
      console.error('Erro ao gerar o PDF:', error);
    }
  };

  return (
    <div className={`logs-filter-modal ${isOpen ? 'show' : ''} ${isClosing ? 'hide' : ''}`} onClick={handleOutsideClick}>
      <div className="logs-filter-modal-content">
        <button className="close-btn" onClick={handleClose}>&times;</button>
        <h2>Filtrar Logs</h2>
        <div className="form-group">
          <label>Data de Início:</label>
          <input 
            type="date" 
            value={startDate} 
            onChange={(e) => setStartDate(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label>Data de Fim:</label>
          <input 
            type="date" 
            value={endDate} 
            onChange={(e) => setEndDate(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <button className="btn btn-primary" onClick={handleGeneratePDF}>Gerar PDF</button>
          <button className="btn btn-secondary" onClick={handleClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default LogsFilterModal;
