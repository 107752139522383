import React, { useEffect, useState } from 'react';
import '../../assets/styles/main.css';
import './css/Dashboard.css';
import ReceitaCard from './ReceitaCard'; 
import DespesaCard from './DespesaCard';
import LancamentosAnoCard from './LancamentosAnoCard';
import CarteirasPieChart from './CarteirasPieChart';
import CartoesPieChart from './CartoesPieChart';
import ReceitasPieChart from './ReceitasPieChart';
import DespesasPieChart from './DespesasPieChart';
import ReceitasDespesasBarChart from './ReceitasDespesasBarChart';
import SerenaBot from './SerenaBot';
import CartoesCreditoTable from './CartoesCreditoTable';
import ReceitasTable from './ReceitasTable';
import DespesasTable from './DespesasTable';
import CarteirasTable from './CarteirasTable';


import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';


const Dashboard = ({ selectedDate }) => {
  const userId = localStorage.getItem('userId');
  const [cardPositions, setCardPositions] = useState({});

  useEffect(() => {
    if (!userId) {
      console.error('User ID not found in localStorage');
      return;
    }

    restoreCardPositions();
    restoreMinimizedState();

    document.querySelectorAll('.primary-inner-card, .danger-inner-card, .info-inner-card, .success-inner-card, .warning-inner-card, .light-inner-card, .dark-inner-card, .secondary-inner-card, .primary-analogous1-inner-card, .primary-analogous2-inner-card, .primary-monochromatic1-inner-card, .primary-monochromatic2-inner-card').forEach(card => {
      card.addEventListener('dragstart', drag);
    });

    document.querySelectorAll('.card').forEach(card => {
      card.addEventListener('dragover', allowDrop);
      card.addEventListener('drop', drop);
    });

    document.querySelectorAll('.minimize-btn').forEach(button => {
      button.addEventListener('click', toggleCardContent);
    });
  }, [userId]);

  useEffect(() => {
    // Este useEffect será disparado sempre que `selectedDate` mudar
  }, [selectedDate]);

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const drag = (event) => {
    event.dataTransfer.setData("text", event.target.id);
  };

  const drop = async (event) => {
    event.preventDefault();
    const draggedCardId = event.dataTransfer.getData("text");
    const draggedCard = document.getElementById(draggedCardId);
    let targetCard = event.target;

    while (!targetCard.classList.contains("card")) {
      targetCard = targetCard.parentNode;
    }

    if (targetCard.classList.contains("primary-inner-card") || 
        targetCard.classList.contains("danger-inner-card") || 
        targetCard.classList.contains("info-inner-card") || 
        targetCard.classList.contains("success-inner-card") || 
        targetCard.classList.contains("warning-inner-card") || 
        targetCard.classList.contains("light-inner-card") || 
        targetCard.classList.contains("dark-inner-card") || 
        targetCard.classList.contains("secondary-inner-card") || 
        targetCard.classList.contains("primary-analogous1-inner-card") || 
        targetCard.classList.contains("primary-analogous2-inner-card") || 
        targetCard.classList.contains("primary-monochromatic1-inner-card") || 
        targetCard.classList.contains("primary-monochromatic2-inner-card") || 
        targetCard.classList.contains("secondary-complementary-inner-card") || 
        targetCard.classList.contains("secondary-analogous1-inner-card") || 
        targetCard.classList.contains("secondary-analogous2-inner-card")) {
      targetCard = targetCard.parentNode;
    }

    if (draggedCard.parentNode === targetCard) {
      return; // Não fazer nada se tentar soltar o cartão dentro do mesmo contêiner
    }

    const existingCard = targetCard.querySelector(".primary-inner-card, .danger-inner-card, .info-inner-card, .success-inner-card, .warning-inner-card, .light-inner-card, .dark-inner-card, .secondary-inner-card, .primary-analogous1-inner-card, .primary-analogous2-inner-card, .primary-monochromatic1-inner-card, .primary-monochromatic2-inner-card, .secondary-complementary-inner-card, .secondary-analogous1-inner-card, .secondary-analogous2-inner-card");
    if (existingCard) {
      const originalParent = draggedCard.parentNode;
      originalParent.appendChild(existingCard);
    }

    targetCard.appendChild(draggedCard);

    await saveCardPositions();
  };

  const saveCardPositions = async () => {
    if (!userId) {
      console.error('User ID is not defined');
      return;
    }

    const cardPositions = {};
    document.querySelectorAll('.primary-inner-card, .danger-inner-card, .info-inner-card, .success-inner-card, .warning-inner-card, .light-inner-card, .dark-inner-card, .secondary-inner-card, .primary-analogous1-inner-card, .primary-analogous2-inner-card, .primary-monochromatic1-inner-card, .primary-monochromatic2-inner-card').forEach(card => {
      cardPositions[card.id] = card.parentNode.id;
    });

    try {
      const response = await fetch(`/api/users/${userId}/saveCardPosition`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(cardPositions),
      });

      if (!response.ok) {
        throw new Error('Falha ao salvar as posições dos cartões');
      }
    } catch (error) {
      console.error("Erro ao salvar as posições dos cartões:", error);
    }
  };

  const restoreCardPositions = async () => {
    if (!userId) {
      console.error('User ID is not defined');
      return;
    }

    try {
      const response = await fetch(`/api/users/${userId}/getCardPositions`);
      const cardPositions = await response.json();
      setCardPositions(cardPositions);

      for (const [cardId, containerId] of Object.entries(cardPositions)) {
        const card = document.getElementById(cardId);
        const container = document.getElementById(containerId);
        if (card && container) {
          container.appendChild(card);
        }
      }
    } catch (error) {
      console.error('Erro ao carregar as posições dos cartões:', error);
    }
  };

  const toggleCardContent = (event) => {
    const button = event.target;
    const card = button.closest('.primary-inner-card, .danger-inner-card, .info-inner-card, .success-inner-card, .warning-inner-card, .light-inner-card, .dark-inner-card, .secondary-inner-card, .primary-analogous1-inner-card, .primary-analogous2-inner-card, .primary-monochromatic1-inner-card, .primary-monochromatic2-inner-card');
    const parentCard = card.parentNode;

    if (card.classList.contains('minimized')) {
      card.classList.remove('minimized');
      parentCard.classList.remove('minimized');
      button.textContent = '-';
    } else {
      card.classList.add('minimized');
      parentCard.classList.add('minimized');
      button.textContent = '+';
    }

    saveMinimizedState();
  };

  const saveMinimizedState = async () => {
    if (!userId) {
      console.error('User ID is not defined');
      return;
    }

    const minimizedStates = {};
    document.querySelectorAll('.primary-inner-card, .danger-inner-card, .info-inner-card, .success-inner-card, .warning-inner-card, .light-inner-card, .dark-inner-card, .secondary-inner-card, .primary-analogous1-inner-card, .primary-analogous2-inner-card, .primary-monochromatic1-inner-card, .primary-monochromatic2-inner-card').forEach(card => {
      minimizedStates[card.id] = card.classList.contains('minimized');
    });

    try {
      const response = await fetch(`/api/users/${userId}/saveMinimizedState`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(minimizedStates),
      });

      if (!response.ok) {
        throw new Error('Falha ao salvar o estado minimizado dos cartões');
      }
    } catch (error) {
      console.error("Erro ao salvar o estado minimizado dos cartões:", error);
    }
  };

  const restoreMinimizedState = async () => {
    if (!userId) {
      console.error('User ID is not defined');
      return;
    }

    try {
      const response = await fetch(`/api/users/${userId}/getMinimizedState`);
      const minimizedStates = await response.json();

      for (const [cardId, isMinimized] of Object.entries(minimizedStates)) {
        const card = document.getElementById(cardId);
        const parentCard = card.parentNode;
        if (card && isMinimized) {
          card.classList.add('minimized');
          parentCard.classList.add('minimized');
          const button = card.querySelector('.minimize-btn');
          if (button) {
            button.textContent = '+';
          }
        }
      }
    } catch (error) {
      console.error('Erro ao restaurar o estado minimizado dos cartões:', error);
    }
  };

  return (
    <main className="admin-container">
      <div className="cards-container">
        <div className="card card-bloco" id="card1" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
            <ReceitaCard selectedDate={selectedDate} userId={userId} />
          </div>

        <div className="card card-bloco" id="card2" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
            <DespesaCard selectedDate={selectedDate} userId={userId} />
        </div>

        <div className="card card-bloco" id="card3" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
        </div>

        <div className="card card-bloco" id="card4" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
        </div>

        <div className="card card-bloco full-width card-tall custom-background" id="card5" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
            <LancamentosAnoCard selectedDate={selectedDate} userId={userId} />     
          </div>
      </div>

      <div className="cards-container">
        <div className="card card-bloco half-width" id="card6" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
          <ReceitasPieChart selectedDate={selectedDate} userId={userId} />
        </div>

        <div className="card card-bloco half-width" id="card7" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
          <DespesasPieChart selectedDate={selectedDate} userId={userId} />
        </div>

        <div className="card card-bloco full-half-width" id="card8" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
          <ReceitasDespesasBarChart userId={userId} />
        </div>
      </div>

      <div className="cards-container">
        <div className="card card-bloco card-33 half-width" id="card9" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
          <CarteirasPieChart userId={userId} />
        </div>

        <div className="card card-bloco card-33 half-width" id="card10" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
          <CartoesPieChart userId={userId} />
        </div>

        <div className="card card-bloco card-33 full-half-width" id="card11" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
          <SerenaBot userId={userId} selectedDate={selectedDate} />
        </div>
      </div>

      <div className="cards-container">
        <div className="card card-bloco half-card cartao-card" id="card12" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
          <CarteirasTable userId={userId} selectedDate={selectedDate}/>
        </div>

        <div className="card card-bloco half-card cartao-card" id="card13" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
          <CartoesCreditoTable userId={userId} selectedDate={selectedDate} />
        </div>
      </div>

      <div className="cards-container">
        <div className="card card-bloco half-card lancamento-card" id="card14" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
          <ReceitasTable userId={userId} selectedDate={selectedDate} />
        </div>

        <div className="card card-bloco half-card lancamento-card" id="card15" onDrop={drop} onDragOver={allowDrop}>
          <div className="card-background"></div>
          <DespesasTable userId={userId} selectedDate={selectedDate} />
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
