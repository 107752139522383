import React, { useState, useEffect } from 'react';
import './OnlineUsersModal.css'; 

const OnlineUsersModal = ({ isOpen, onClose, onUserDisconnect }) => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetch('/api/admin/online_users')
        .then((res) => res.json())
        .then((data) => setOnlineUsers(data))
        .catch((err) => console.error('Erro ao buscar usuários online:', err));
    }
  }, [isOpen]);

  const handleDisconnect = (userId) => {
    fetch('/api/admin/disconnect_user', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: userId }),
    })
      .then(() => {
        setOnlineUsers((prev) => prev.filter((user) => user.id !== userId));
        onUserDisconnect();  // Chama a função para atualizar as estatísticas
      })
      .catch((err) => console.error('Erro ao desconectar usuário:', err));
  };

  const handleOutsideClick = (e) => {
    if (e.target.className.includes('online-users-modal')) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  const filteredUsers = onlineUsers.filter((user) =>
    user.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className={`online-users-modal ${isOpen ? 'show' : ''} ${isClosing ? 'hide' : ''}`} onClick={handleOutsideClick}>
      <div className="modal-content">
        <button className="close-btn" onClick={handleClose}>&times;</button>
        <h2>Usuários Online</h2>
        <input
          type="text"
          placeholder="Pesquisar usuário..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome</th>
              <th>Email</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.nome}</td>
                <td>{user.email}</td>
                <td>
                  <button onClick={() => handleDisconnect(user.id)}>Desconectar</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OnlineUsersModal;
