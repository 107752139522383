import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaFileAlt, FaLock, FaUnlock } from 'react-icons/fa'; // Importar ícones
import '../../assets/styles/main.css';
import './admin.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MaintenanceModal from './modal/MaintenanceModal';
import OnlineUsersModal from './modal/OnlineUsersModal';
import BlockedUsersModal from './modal/BlockedUsersModal';
import EditUserModal from './modal/EditUserModal'; // Importando o EditUserModal
import LogsFilterModal from './modal/LogsFilterModal'; // Importando o novo LogsFilterModal

const Admin = () => {
  // Estado para o LogsFilterModal
  const [isLogsFilterModalOpen, setIsLogsFilterModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null); // Estado para armazenar o ID do usuário selecionado
  
  const [userStats, setUserStats] = useState({});
  const [adminList, setAdminList] = useState([]);
  const [vipList, setVipList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [vipSearchTerm, setVipSearchTerm] = useState('');
  const [userSearchTerm, setUserSearchTerm] = useState('');
  const [adminPage, setAdminPage] = useState(1);
  const [vipPage, setVipPage] = useState(1);
  const [userPage, setUserPage] = useState(1);
  const itemsPerPage = 10;
  const [serverStatus, setServerStatus] = useState('Online');
  const [isMaintenanceModalOpen, setIsMaintenanceModalOpen] = useState(false);
  const [isOnlineUsersModalOpen, setIsOnlineUsersModalOpen] = useState(false);
  const [isBlockedUsersModalOpen, setIsBlockedUsersModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [newServerStatus, setNewServerStatus] = useState('online');
  const [editingUser, setEditingUser] = useState(null); // Estado para o usuário que está sendo editado
  const navigate = useNavigate();

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (!userDetails || userDetails.adm !== 1) {
      navigate('/');
      return;
    }

    fetchUserStats();
    fetchAdminList();
    fetchVipList();
    fetchUserList();
    fetchServerStatus();
  }, [navigate]);

  const fetchUserStats = async () => {
    try {
      const response = await fetch('/api/admin/user_stats');
      const data = await response.json();
      setUserStats(data);
    } catch (error) {
      toast.error('Erro ao buscar estatísticas dos usuários');
    }
  };

  const fetchAdminList = async () => {
    try {
      const response = await fetch('/api/admin/admin_list');
      const data = await response.json();
      setAdminList(data);
    } catch (error) {
      toast.error('Erro ao buscar lista de administradores');
    }
  };

  const fetchVipList = async () => {
    try {
      const response = await fetch('/api/admin/vip_list');
      const data = await response.json();
      setVipList(data);
    } catch (error) {
      toast.error('Erro ao buscar lista de usuários VIP');
    }
  };

  const fetchUserList = async () => {
    try {
      const response = await fetch('/api/admin/user_list');
      const data = await response.json();
      setUserList(data);
    } catch (error) {
      toast.error('Erro ao buscar lista de usuários');
    }
  };

  const fetchServerStatus = async () => {
    try {
      const response = await fetch('/api/admin/server_status');
      const data = await response.json();
      setServerStatus(data.restriction_type === 'online' ? 'Online' : 'Em manutenção');
    } catch (error) {
      toast.error('Erro ao buscar status do servidor');
    }
  };

  const handleBlockToggle = async (userId, currentStatus) => {
    try {
      const endpoint = currentStatus === 2 ? '/api/admin/unblock_user' : '/api/admin/block_user';
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id: userId }),
      });

      if (response.ok) {
        setUserList(prevList =>
          prevList.map(user =>
            user.id === userId ? { ...user, status_conexao: currentStatus === 2 ? 0 : 2 } : user
          )
        );
        toast.success(`Usuário ${currentStatus === 2 ? 'desbloqueado' : 'bloqueado'} com sucesso`);
      } else {
        throw new Error('Erro na requisição de bloqueio/desbloqueio');
      }
    } catch (error) {
      toast.error(`Erro ao ${currentStatus === 2 ? 'desbloquear' : 'bloquear'} usuário`);
    }
  };

  const handleOpenEditUserModal = (user) => {
    setEditingUser(user); // Define o usuário que está sendo editado
    setIsEditUserModalOpen(true); // Abre o modal de edição
  };

  const handleCloseEditUserModal = () => {
    setIsEditUserModalOpen(false); // Fecha o modal de edição
    setEditingUser(null); // Limpa o usuário sendo editado
  };

  const handleSaveUserChanges = async (updatedUser) => {
    try {
      const response = await fetch('/api/admin/edit_user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUser),
      });

      if (response.ok) {
        setUserList(prevList =>
          prevList.map(user =>
            user.id === updatedUser.id ? updatedUser : user
          )
        );
        toast.success('Usuário atualizado com sucesso');
      } else {
        throw new Error('Erro ao salvar alterações do usuário');
      }
    } catch (error) {
      toast.error('Erro ao salvar alterações do usuário');
    }

    handleCloseEditUserModal(); // Fecha o modal após salvar
  };

  const handleOpenMaintenanceModal = () => {
    setIsMaintenanceModalOpen(true);
  };

  const handleCloseMaintenanceModal = () => {
    setIsMaintenanceModalOpen(false);
  };

  const handleOpenOnlineUsersModal = () => {
    setIsOnlineUsersModalOpen(true);
  };

  const handleCloseOnlineUsersModal = () => {
    setIsOnlineUsersModalOpen(false);
  };

  const handleOpenBlockedUsersModal = () => {
    setIsBlockedUsersModalOpen(true);
  };

  const handleCloseBlockedUsersModal = () => {
    setIsBlockedUsersModalOpen(false);
  };

  const handleSaveMaintenanceStatus = async () => {
    try {
      await fetch('/api/admin/server_status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ restriction_type: newServerStatus }),
      });
      toast.success('Status do servidor atualizado com sucesso');
      fetchServerStatus();
      handleCloseMaintenanceModal();
    } catch (error) {
      toast.error('Erro ao atualizar status do servidor');
    }
  };

  const getPlanDescription = (planLevel) => {
    switch (planLevel) {
      case 1:
        return 'Gratuito';
      case 2:
        return 'Premium';
      case 3:
        return 'Premium + Investimento';
      case 4:
        return 'VIP';
      default:
        return 'Desconhecido';
    }
  };

  const filteredAdminList = adminList.filter(admin =>
    admin.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    admin.sobrenome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    admin.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredVipList = vipList.filter(vip =>
    vip.nome.toLowerCase().includes(vipSearchTerm.toLowerCase()) ||
    vip.sobrenome.toLowerCase().includes(vipSearchTerm.toLowerCase()) ||
    vip.email.toLowerCase().includes(vipSearchTerm.toLowerCase())
  );

  const filteredUserList = userList.filter(user =>
    user.nome.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
    user.sobrenome.toLowerCase().includes(userSearchTerm.toLowerCase()) ||
    user.email.toLowerCase().includes(userSearchTerm.toLowerCase())
  );

  // Paginação
  const paginate = (items, page) => {
    const startIndex = (page - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const handleAdminPageChange = (direction) => {
    setAdminPage(prevPage => prevPage + direction);
  };

  const handleVipPageChange = (direction) => {
    setVipPage(prevPage => prevPage + direction);
  };

  const handleUserPageChange = (direction) => {
    setUserPage(prevPage => prevPage + direction);
  };

  // Funções para abrir/fechar o LogsFilterModal
  const handleOpenLogsFilterModal = (userId) => {
    setSelectedUserId(userId); // Aqui, armazenamos o ID do usuário selecionado
    setIsLogsFilterModalOpen(true);
  };

  const handleCloseLogsFilterModal = () => {
    setIsLogsFilterModalOpen(false);
    setSelectedUserId(null); // Limpa o ID do usuário selecionado quando o modal fecha
  };

  return (
    <div className="admin-container">
      <ToastContainer />
      <div className={`server-status ${serverStatus === 'Online' ? 'online' : 'offline'}`}>
        Servidor está {serverStatus}
      </div>
      <h1>Painel do Administrador</h1>

      <button className="maintenance-btn" onClick={handleOpenMaintenanceModal}>
        Configurar Manutenção
      </button>

      <MaintenanceModal
        isOpen={isMaintenanceModalOpen}
        onClose={handleCloseMaintenanceModal}
        onSave={handleSaveMaintenanceStatus}
        status={newServerStatus}
        setStatus={setNewServerStatus}
      />

      <OnlineUsersModal
        isOpen={isOnlineUsersModalOpen}
        onClose={handleCloseOnlineUsersModal}
        onUserDisconnect={fetchUserStats}  // Atualiza as estatísticas após desconectar um usuário
      />

      <BlockedUsersModal
        isOpen={isBlockedUsersModalOpen}
        onClose={handleCloseBlockedUsersModal}
        onUserUnblock={fetchUserStats}  // Atualiza as estatísticas após desbloquear um usuário
      />

      <EditUserModal
        isOpen={isEditUserModalOpen}
        onClose={handleCloseEditUserModal}
        onSave={handleSaveUserChanges}
        user={editingUser} // Passa o usuário sendo editado para o modal
      />

      <LogsFilterModal
        isOpen={isLogsFilterModalOpen}
        onClose={handleCloseLogsFilterModal}
        userId={selectedUserId} // Passa o ID do usuário selecionado para o modal
      />

      <div className="stats-container">
        <div className="stat-box">
          <h6>Usuários Cadastrados</h6>
          <p>{userStats.totalUsers}</p>
        </div>
        <div className="stat-box cursor-box" onClick={handleOpenOnlineUsersModal}>
          <h6>Usuários Online</h6>
          <p>{userStats.loggedInUsers}</p>
        </div>
        <div className="stat-box">
          <h6>Usuários Gratuitos</h6>
          <p>{userStats.freePlanUsers}</p>
        </div>
        <div className="stat-box">
          <h6>Plano Premium</h6>
          <p>{userStats.premiumPlanUsers}</p>
        </div>
        <div className="stat-box">
          <h6>Premium + Investimento</h6>
          <p>{userStats.premiumInvestmentPlanUsers}</p>
        </div>
        <div className="stat-box">
          <h6>Plano VIP</h6>
          <p>{userStats.vipPlanUsers}</p>
        </div>
        <div className="stat-box cursor-box" onClick={handleOpenBlockedUsersModal}>
          <h6>Usuários Bloqueados</h6>
          <p>{userStats.blockedUsers}</p>
        </div>
        <div className="stat-box">
          <h6>Usuários por País</h6>
          <ul>
            {userStats.usersByCountry?.map((country, index) => (
              <li key={index}>{country.name}: {country.count}</li>
            ))}
          </ul>
        </div>
      </div>

      {/* Tabela de Administradores */}
      <div className="table-container">
        <h3>Administradores</h3>
        <input 
          type="text" 
          placeholder="Pesquisar administradores..." 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o termo de pesquisa
          className="search-input"
        />
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome Completo</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>País</th>
              <th>Moeda</th>
              <th>Idioma</th>
              <th>Plano Atual</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {paginate(filteredAdminList, adminPage).map(admin => (
              <tr key={admin.id}>
                <td>{admin.id}</td>
                <td>{admin.nome} {admin.sobrenome}</td>
                <td>{admin.email}</td>
                <td>{admin.tel_contato}</td>
                <td>{admin.pais_padrao}</td>
                <td>{admin.moeda_primaria}</td>
                <td>{admin.idioma_padrao}</td>
                <td>{getPlanDescription(admin.niv_usuario)}</td>
                <td className="action-buttons">
                  <button className="edit-btn" onClick={() => handleOpenEditUserModal(admin)}><FaEdit /></button>
                  <button className="log-btn" onClick={() => handleOpenLogsFilterModal(admin.id)}>
                    <FaFileAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => handleAdminPageChange(-1)} disabled={adminPage === 1}>Anterior</button>
          <span>Página {adminPage}</span>
          <button onClick={() => handleAdminPageChange(1)} disabled={adminPage * itemsPerPage >= filteredAdminList.length}>Próximo</button>
        </div>
      </div>

      {/* Tabela de Usuários VIP */}
      <div className="table-container">
        <h3>Usuários VIP</h3>
        <input 
          type="text" 
          placeholder="Pesquisar usuários VIP..." 
          value={vipSearchTerm}
          onChange={(e) => setVipSearchTerm(e.target.value)} // Atualiza o termo de pesquisa para VIPs
          className="search-input"
        />
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome Completo</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>País</th>
              <th>Moeda</th>
              <th>Idioma</th>
              <th>Plano Atual</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {paginate(filteredVipList, vipPage).map(vip => (
              <tr key={vip.id}>
                <td>{vip.id}</td>
                <td>{vip.nome} {vip.sobrenome}</td>
                <td>{vip.email}</td>
                <td>{vip.tel_contato}</td>
                <td>{vip.pais_padrao}</td>
                <td>{vip.moeda_primaria}</td>
                <td>{vip.idioma_padrao}</td>
                <td>{getPlanDescription(vip.niv_usuario)}</td>
                <td className="action-buttons">
                  <button className="edit-btn" onClick={() => handleOpenEditUserModal(vip)}><FaEdit /></button>
                  <button className="log-btn" onClick={() => handleOpenLogsFilterModal(vip.id)}>
                    <FaFileAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => handleVipPageChange(-1)} disabled={vipPage === 1}>Anterior</button>
          <span>Página {vipPage}</span>
          <button onClick={() => handleVipPageChange(1)} disabled={vipPage * itemsPerPage >= filteredVipList.length}>Próximo</button>
        </div>
      </div>

      {/* Tabela de Usuários Cadastrados */}
      <div className="table-container">
        <h3>Usuários Cadastrados</h3>
        <input 
          type="text" 
          placeholder="Pesquisar usuários cadastrados..." 
          value={userSearchTerm}
          onChange={(e) => setUserSearchTerm(e.target.value)} // Atualiza o termo de pesquisa para usuários
          className="search-input"
        />
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome Completo</th>
              <th>Email</th>
              <th>Telefone</th>
              <th>País</th>
              <th>Moeda</th>
              <th>Idioma</th>
              <th>Plano Atual</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {paginate(filteredUserList, userPage).map(user => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.nome} {user.sobrenome}</td>
                <td>{user.email}</td>
                <td>{user.tel_contato}</td>
                <td>{user.pais_padrao}</td>
                <td>{user.moeda_primaria}</td>
                <td>{user.idioma_padrao}</td>
                <td>{getPlanDescription(user.niv_usuario)}</td>
                <td className="action-buttons">
                  <button 
                    className={`block-btn ${user.status_conexao === 2 ? 'blocked' : 'unblocked'}`} 
                    onClick={() => handleBlockToggle(user.id, user.status_conexao)}
                  >
                    {user.status_conexao === 2 ? <FaLock /> : <FaUnlock />}
                  </button>
                  <button className="edit-btn" onClick={() => handleOpenEditUserModal(user)}><FaEdit /></button>
                  <button className="log-btn" onClick={() => handleOpenLogsFilterModal(user.id)}>
                    <FaFileAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <button onClick={() => handleUserPageChange(-1)} disabled={userPage === 1}>Anterior</button>
          <span>Página {userPage}</span>
          <button onClick={() => handleUserPageChange(1)} disabled={userPage * itemsPerPage >= filteredUserList.length}>Próximo</button>
        </div>
      </div>
    </div>
  );
};

export default Admin;
