import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import 'react-toastify/dist/ReactToastify.css';
import './LancamentoModal.css';

const LancamentoModal = ({ isOpen, onClose, tipoLancamentoInicial }) => {
    const [tipoLancamento, setTipoLancamento] = useState(tipoLancamentoInicial || ''); 
    const [carteira, setCarteira] = useState('');
    const [novaCarteira, setNovaCarteira] = useState('');
    const [adicionandoCarteira, setAdicionandoCarteira] = useState(false);
    const [valor, setValor] = useState('');
    const [descricao, setDescricao] = useState('');
    const [data, setData] = useState('');
    const [categoria, setCategoria] = useState('');
    const [novaCategoria, setNovaCategoria] = useState('');
    const [adicionandoCategoria, setAdicionandoCategoria] = useState(false);
    const [formaDePagamento, setFormaDePagamento] = useState('');
    const [novaFormaDePagamento, setNovaFormaDePagamento] = useState('');
    const [adicionandoFormaDePagamento, setAdicionandoFormaDePagamento] = useState(false);
    const [moeda, setMoeda] = useState('BRL');
    const [carteiras, setCarteiras] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [formasDePagamento, setFormasDePagamento] = useState([]);
    const [isClosing, setIsClosing] = useState(false);

    // Novos estados para recorrência
    const [isRecorrente, setIsRecorrente] = useState(false);
    const [frequenciaRecorrencia, setFrequenciaRecorrencia] = useState('mensal');
    const [dataFimRecorrencia, setDataFimRecorrencia] = useState('');

    // Novos estados para cartões de crédito
    const [cartoesCredito, setCartoesCredito] = useState([]);
    const [cartaoCredito, setCartaoCredito] = useState('');
    const [novoCartaoCredito, setNovoCartaoCredito] = useState('');
    const [adicionandoCartaoCredito, setAdicionandoCartaoCredito] = useState(false);

    const [validation, setValidation] = useState({
        tipoLancamento: true,
        carteira: true,
        valor: true,
        descricao: true,
        data: true,
        categoria: true,
        formaDePagamento: true
    });

    const [isFormValid, setIsFormValid] = useState(false);

    // Carregar carteiras e formas de pagamento quando o modal for aberto
    useEffect(() => {
        if (isOpen) {
            const userId = localStorage.getItem('userId');
            fetch(`/api/users/${userId}/carteiras`)
                .then(res => res.json())
                .then(data => {
                    setCarteiras(data.carteiras || []);
                })
                .catch(err => console.error('Erro ao carregar carteiras:', err));

            fetch(`/api/formasdepagamento/${userId}`)
                .then(res => res.json())
                .then(data => {
                    setFormasDePagamento(data.formas_de_pagamento || []);
                })
                .catch(err => console.error('Erro ao carregar formas de pagamento:', err));
        }
    }, [isOpen]);

    // Carregar cartões de crédito quando o tipo de lançamento for "Despesa em Cartão"
    useEffect(() => {
        if (tipoLancamento === 'Despesa em Cartão' && isOpen) {
            const userId = localStorage.getItem('userId');
            fetch(`/api/users/${userId}/cartoes-credito`)
                .then(res => res.json())
                .then(data => {
                    setCartoesCredito(data.cartoes || []);
                })
                .catch(err => console.error('Erro ao carregar cartões de crédito:', err));
        }
    }, [tipoLancamento, isOpen]);

    // Carregar categorias com base no tipo de lançamento
    useEffect(() => {
        if (tipoLancamento) {
            const userId = localStorage.getItem('userId');
            fetch(`/api/categorias/${userId}?tipo_categoria=${tipoLancamento === 'Despesa em Cartão' ? 'Despesa' : tipoLancamento}`)
                .then(res => res.json())
                .then(data => {
                    setCategorias(data.categorias || []);
                })
                .catch(err => console.error('Erro ao carregar categorias:', err));
        }
    }, [tipoLancamento]);

    const formatarValor = (valor) => {
        let val = valor.replace(/\D/g, '');
        val = (val / 100).toFixed(2);
        val = val.replace('.', ',');
        val = val.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return val;
    };

    const handleValorChange = (e) => {
        const valorFormatado = formatarValor(e.target.value);
        setValor(valorFormatado);
    };

    // Verifica se já existe uma carteira com o mesmo nome
    const handleCarteiraKeyDown = (e) => {
        if (e.key === 'Enter' && novaCarteira) {
            e.preventDefault();
            const userId = localStorage.getItem('userId');
            const carteiraExistente = carteiras.find(c => c.nome.toLowerCase() === novaCarteira.toLowerCase());

            if (carteiraExistente) {
                toast.error('Já existe uma carteira com este nome.');
                return;
            }

            fetch(`/api/users/${userId}/carteiras`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    nome: novaCarteira,
                    tipo: 'Conta Corrente',
                    saldo_inicial: 1,
                    saldo_atual: 1,
                    moeda: 'BRL',
                    ativa: 1
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (data.message === 'Carteira cadastrada com sucesso.') {
                    const novaCarteiraAdicionada = { 
                        id: data.id_carteira || Math.random().toString(36).substr(2, 9), 
                        nome: novaCarteira
                    };
                    setCarteiras([...carteiras, novaCarteiraAdicionada]);
                    setCarteira(novaCarteiraAdicionada.id);
                    setNovaCarteira('');
                    setAdicionandoCarteira(false);
                } else {
                    toast.error('Erro ao adicionar carteira.');
                }
            })
            .catch(err => {
                toast.error('Erro ao adicionar carteira.');
            });
        } else if (e.key === 'Escape') {
            setNovaCarteira('');
            setAdicionandoCarteira(false);
        }
    };

    // Verifica se já existe um cartão de crédito com o mesmo nome
    const handleCartaoCreditoKeyDown = (e) => {
        if (e.key === 'Enter' && novoCartaoCredito) {
            e.preventDefault();
            const userId = localStorage.getItem('userId');
            const cartaoExistente = cartoesCredito.find(c => c.nome.toLowerCase() === novoCartaoCredito.toLowerCase());

            if (cartaoExistente) {
                toast.error('Já existe um cartão de crédito com este nome.');
                return;
            }

            fetch(`/api/users/${userId}/cartoes-credito`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    nome: novoCartaoCredito,
                    limite_credito: 1000,
                    saldo_disponivel: 1000,
                    data_validade: '2025-12-31',
                    data_vencimento_fatura: '2024-09-15',
                    data_virada_fatura: '2024-09-01',
                    moeda: 'BRL',
                    ativa: 1
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (data.message === 'Cartão de crédito cadastrado com sucesso.') {
                    const novoCartaoAdicionado = { 
                        id: data.id_cartao || Math.random().toString(36).substr(2, 9), 
                        nome: novoCartaoCredito
                    };
                    setCartoesCredito([...cartoesCredito, novoCartaoAdicionado]);
                    setCartaoCredito(novoCartaoAdicionado.id);
                    setNovoCartaoCredito('');
                    setAdicionandoCartaoCredito(false);

                    // Atualiza a lista de cartões após a criação
                    fetch(`/api/users/${userId}/cartoes-credito`)
                        .then(res => res.json())
                        .then(data => {
                            setCartoesCredito(data.cartoes || []);
                            setCartaoCredito(novoCartaoAdicionado.id); // Seleciona o novo cartão criado
                        })
                        .catch(err => console.error('Erro ao recarregar cartões de crédito:', err));
                } else {
                    toast.error('Erro ao adicionar cartão de crédito.');
                }
            })
            .catch(err => {
                toast.error('Erro ao adicionar cartão de crédito.');
            });
        } else if (e.key === 'Escape') {
            setNovoCartaoCredito('');
            setAdicionandoCartaoCredito(false);
        }
    };

    // Verifica se já existe uma categoria com o mesmo nome
    const handleCategoriaKeyDown = (e) => {
        if (e.key === 'Enter' && novaCategoria) {
            e.preventDefault();
            const userId = localStorage.getItem('userId');
            let tipoCategoria = tipoLancamento === 'Despesa em Cartão' ? 'Despesa' : tipoLancamento;
            const categoriaExistente = categorias.find(c => c.nome_categoria.toLowerCase() === novaCategoria.toLowerCase());

            if (categoriaExistente) {
                toast.error('Já existe uma categoria com este nome.');
                return;
            }

            fetch(`/api/categorias`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id_usuario: userId,
                    tipo_categoria: tipoCategoria,
                    nome_categoria: novaCategoria
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (data.message === 'Categoria cadastrada com sucesso.') {
                    const novaCategoriaAdicionada = { 
                        id_categoria: data.id_categoria || Math.random().toString(36).substr(2, 9), 
                        nome_categoria: novaCategoria
                    };
                    setCategorias([...categorias, novaCategoriaAdicionada]);
                    setCategoria(novaCategoriaAdicionada.nome_categoria);
                    setNovaCategoria('');
                    setAdicionandoCategoria(false);
                } else {
                    toast.error('Erro ao adicionar categoria.');
                }
            })
            .catch(err => {
                toast.error('Erro ao adicionar categoria.');
            });
        } else if (e.key === 'Escape') {
            setNovaCategoria('');
            setAdicionandoCategoria(false);
        }
    };

    // Verifica se já existe uma forma de pagamento com o mesmo nome
    const handleFormaPagamentoKeyDown = (e) => {
        if (e.key === 'Enter' && novaFormaDePagamento) {
            e.preventDefault();
            const userId = localStorage.getItem('userId');
            const formaPagamentoExistente = formasDePagamento.find(f => f.nome_formapagamento.toLowerCase() === novaFormaDePagamento.toLowerCase());

            if (formaPagamentoExistente) {
                toast.error('Já existe uma forma de pagamento com este nome.');
                return;
            }

            fetch(`/api/formasdepagamento`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    id_usuario: userId,
                    nome_formapagamento: novaFormaDePagamento
                }),
            })
            .then(res => res.json())
            .then(data => {
                if (data.message === 'Forma de pagamento cadastrada com sucesso.') {
                    const novaFormaAdicionada = { 
                        id_formapagamento: data.id_formapagamento || Math.random().toString(36).substr(2, 9), 
                        nome_formapagamento: novaFormaDePagamento
                    };
                    setFormasDePagamento([...formasDePagamento, novaFormaAdicionada]);
                    setFormaDePagamento(novaFormaAdicionada.nome_formapagamento);
                    setNovaFormaDePagamento('');
                    setAdicionandoFormaDePagamento(false);
                } else {
                    toast.error('Erro ao adicionar forma de pagamento.');
                }
            })
            .catch(err => {
                toast.error('Erro ao adicionar forma de pagamento.');
            });
        } else if (e.key === 'Escape') {
            setNovaFormaDePagamento('');
            setAdicionandoFormaDePagamento(false);
        }
    };

    // Função para validar o formulário
    const validateForm = () => {
        const validationState = {
            tipoLancamento: !!tipoLancamento,
            carteira: tipoLancamento === 'Despesa em Cartão' ? !!cartaoCredito : !!carteira,
            valor: !!valor,
            descricao: !!descricao,
            data: !!data,
            categoria: !!categoria,
            formaDePagamento: !!formaDePagamento
        };

        setValidation(validationState);
        return Object.values(validationState).every(Boolean);
    };

    useEffect(() => {
        setIsFormValid(validateForm());
    }, [tipoLancamento, carteira, cartaoCredito, valor, descricao, data, categoria, formaDePagamento]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const userId = localStorage.getItem('userId');
        const isValid = validateForm();
    
        if (!isValid) {
            toast.error('Preencha todos os campos obrigatórios.');
            return;
        }
    
        const valorNumerico = parseFloat(valor.replace(/\./g, '').replace(',', '.'));
    
        let carteiraOuCartaoSelecionado = null;
    
        if (tipoLancamento === 'Despesa em Cartão') {
            // Verificar se um cartão de crédito foi selecionado corretamente
            carteiraOuCartaoSelecionado = cartoesCredito.find(c => String(c.nome) === String(cartaoCredito));
        } else {
            // Verificar se uma carteira foi selecionada corretamente
            carteiraOuCartaoSelecionado = carteiras.find(c => String(c.id) === String(carteira));
        }
    
        // Se não encontrar nem carteira nem cartão, exibe o erro
        if (!carteiraOuCartaoSelecionado) {
            console.error(`Erro ao buscar a carteira ou o cartão. Valor de cartaoCredito: ${cartaoCredito}, Valor de carteira: ${carteira}`);
            toast.error('Erro ao buscar a carteira ou o cartão. Por favor, tente novamente.');
            return;
        }
    
        let novoSaldo;
        if (tipoLancamento === 'Receita') {
            novoSaldo = parseFloat(carteiraOuCartaoSelecionado.saldo_atual) + valorNumerico;
        } else if (tipoLancamento === 'Despesa') {
            novoSaldo = parseFloat(carteiraOuCartaoSelecionado.saldo_atual) - valorNumerico;
        } else if (tipoLancamento === 'Despesa em Cartão') {
            novoSaldo = parseFloat(carteiraOuCartaoSelecionado.saldo_disponivel) - valorNumerico;
        }
    
        const body = {
            tipo_lancamento: tipoLancamento,
            valor: valorNumerico,
            data,
            descricao,
            carteira: tipoLancamento === 'Despesa em Cartão' ? carteiraOuCartaoSelecionado.nome : carteiraOuCartaoSelecionado.nome,
            categoria,
            forma_de_pagamento: formaDePagamento,
            moeda,
            novo_saldo: novoSaldo
        };
    
        if (isRecorrente) {
            body.recorrente = true;
            body.frequencia = frequenciaRecorrencia;
            body.data_fim = dataFimRecorrencia;
        }
    
        // Realiza o lançamento
        fetch(`/api/users/${userId}/lancamentos`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        })
        .then(res => res.json())
        .then(data => {
            if (data.message && data.message.includes('sucesso')) {
                toast.success('Lançamento realizado com sucesso!', { autoClose: 5000 });
        
                
                // Se o lançamento for uma "Despesa em Cartão", atualiza o saldo do cartão
                if (tipoLancamento === 'Despesa em Cartão') {
                    atualizarSaldoCartaoCredito(carteiraOuCartaoSelecionado, novoSaldo);
                } else {
                    setTimeout(() => {
                        handleClose();
                    }, 500);
                }
            } else {
                toast.error(data.message || 'Erro desconhecido.');
            }
        })
        .catch(err => {
            toast.error('Erro ao realizar o lançamento.');
        });
    };
    
    // Função para atualizar o saldo do cartão de crédito
    const atualizarSaldoCartaoCredito = (cartao, novoSaldo) => {
        const userId = localStorage.getItem('userId');
        
        const body = {
            saldo_disponivel: novoSaldo
        };
    
        // Chama a API para atualizar o saldo do cartão de crédito
        fetch(`/api/users/${userId}/cartoes-credito/${cartao.id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
        })
        .then(res => res.json())
        .then(data => {
            if (data.message === 'Saldo atualizado com sucesso.') {
                toast.success('Saldo do cartão de crédito atualizado!', { autoClose: 5000 });
                setTimeout(() => {
                    handleClose();
                }, 500);
            } else {
                toast.error('Erro ao atualizar saldo do cartão.');
            }
        })
        .catch(err => {
            toast.error('Erro ao atualizar saldo do cartão.');
        });
    };

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            onClose();
            setIsClosing(false);
        }, 300);
    };

    const handleModalContentClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div className={`lancamento-modal ${isOpen && !isClosing ? 'show' : ''} ${isClosing ? 'hide' : ''}`} onClick={handleClose}>
            <div className="lancamento-modal-content" onClick={handleModalContentClick}>
                <button className="close-btn" onClick={handleClose}>&times;</button>
                <h2>Novo Lançamento</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Tipo de Lançamento:</label>
                        <select 
                            value={tipoLancamento} 
                            onChange={(e) => setTipoLancamento(e.target.value)} 
                            required 
                            className={!validation.tipoLancamento ? 'invalid' : ''}
                        >
                            <option value="">Selecione</option>
                            <option value="Receita">Receita</option>
                            <option value="Despesa">Despesa</option>
                            <option value="Despesa em Cartão">Despesa em Cartão</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>{tipoLancamento === 'Despesa em Cartão' ? 'Cartão de Crédito' : 'Carteira'}:</label>
                        <div className="select-with-add">
                            {tipoLancamento === 'Despesa em Cartão' ? (
                                <>
                                    {adicionandoCartaoCredito ? (
                                        <input 
                                            type="text" 
                                            value={novoCartaoCredito} 
                                            onChange={(e) => setNovoCartaoCredito(e.target.value)} 
                                            onKeyDown={handleCartaoCreditoKeyDown}
                                            placeholder="Digite novo cartão..." 
                                            className={`input-add-carteira ${!validation.carteira ? 'invalid' : ''}`}
                                        />
                                    ) : (
                                        <>
                                            <select 
                                                value={cartaoCredito} 
                                                onChange={(e) => setCartaoCredito(e.target.value)} 
                                                className={!validation.carteira ? 'invalid' : ''}
                                            >
                                                <option value="">Selecione</option>
                                                {cartoesCredito.length > 0 ? (
                                                    cartoesCredito.map((c) => (
                                                        <option key={c.id || c.nome} value={c.id}>
                                                            {c.nome}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value="">Nenhum cartão disponível</option>
                                                )}
                                            </select>
                                            <FontAwesomeIcon 
                                                icon={faPlus} 
                                                className="add-carteira-icon" 
                                                onClick={() => setAdicionandoCartaoCredito(true)} 
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {adicionandoCarteira ? (
                                        <input 
                                            type="text" 
                                            value={novaCarteira} 
                                            onChange={(e) => setNovaCarteira(e.target.value)} 
                                            onKeyDown={handleCarteiraKeyDown}
                                            placeholder="Digite nova carteira..." 
                                            className={`input-add-carteira ${!validation.carteira ? 'invalid' : ''}`}
                                        />
                                    ) : (
                                        <>
                                            <select 
                                                value={carteira} 
                                                onChange={(e) => setCarteira(e.target.value)} 
                                                className={!validation.carteira ? 'invalid' : ''}
                                            >
                                                <option value="">Selecione</option>
                                                {carteiras.length > 0 ? (
                                                    carteiras.map((c) => (
                                                        <option key={c.id || c.nome} value={c.id}>
                                                            {c.nome}
                                                        </option>
                                                    ))
                                                ) : (
                                                    <option value="">Nenhuma carteira disponível</option>
                                                )}
                                            </select>
                                            <FontAwesomeIcon 
                                                icon={faPlus} 
                                                className="add-carteira-icon" 
                                                onClick={() => setAdicionandoCarteira(true)} 
                                            />
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Valor:</label>
                        <input 
                            type="text" 
                            value={valor} 
                            onChange={handleValorChange} 
                            required 
                            placeholder="0,00" 
                            className={!validation.valor ? 'invalid' : ''}
                        />
                    </div>
                    <div className="form-group">
                        <label>Data:</label>
                        <input 
                            type="date" 
                            value={data} 
                            onChange={(e) => setData(e.target.value)} 
                            required 
                            className={!validation.data ? 'invalid' : ''}
                        />
                    </div>
                    <div className="form-group">
                        <label>Categoria:</label>
                        <div className="select-with-add">
                            {adicionandoCategoria ? (
                                <input 
                                    type="text" 
                                    value={novaCategoria} 
                                    onChange={(e) => setNovaCategoria(e.target.value)} 
                                    onKeyDown={handleCategoriaKeyDown}
                                    placeholder="Digite nova categoria..." 
                                    className={`input-add-category ${!validation.categoria ? 'invalid' : ''}`}
                                />
                            ) : (
                                <>
                                    <select 
                                        value={categoria} 
                                        onChange={(e) => setCategoria(e.target.value)} 
                                        className={!validation.categoria ? 'invalid' : ''}
                                    >
                                        <option value="">Selecione</option>
                                        {categorias.length > 0 ? (
                                            categorias.map((cat) => (
                                                <option key={cat.id_categoria || cat.nome_categoria} value={cat.nome_categoria}>
                                                    {cat.nome_categoria}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">Nenhuma categoria disponível</option>
                                        )}
                                    </select>
                                    <FontAwesomeIcon 
                                        icon={faPlus} 
                                        className="add-category-icon" 
                                        onClick={() => setAdicionandoCategoria(true)} 
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Forma de Pagamento:</label>
                        <div className="select-with-add">
                            {adicionandoFormaDePagamento ? (
                                <input 
                                    type="text" 
                                    value={novaFormaDePagamento} 
                                    onChange={(e) => setNovaFormaDePagamento(e.target.value)} 
                                    onKeyDown={handleFormaPagamentoKeyDown}
                                    placeholder="Digite nova forma de pagamento..." 
                                    className={`input-add-forma ${!validation.formaDePagamento ? 'invalid' : ''}`}
                                />
                            ) : (
                                <>
                                    <select 
                                        value={formaDePagamento} 
                                        onChange={(e) => setFormaDePagamento(e.target.value)} 
                                        className={!validation.formaDePagamento ? 'invalid' : ''}
                                    >
                                        <option value="">Selecione</option>
                                        {formasDePagamento.length > 0 ? (
                                            formasDePagamento.map((fp) => (
                                                <option key={fp.id_formapagamento || fp.nome_formapagamento} value={fp.nome_formapagamento}>
                                                    {fp.nome_formapagamento}
                                                </option>
                                            ))
                                        ) : (
                                            <option value="">Nenhuma forma de pagamento disponível</option>
                                        )}
                                    </select>
                                    <FontAwesomeIcon 
                                        icon={faPlus} 
                                        className="add-forma-icon" 
                                        onClick={() => setAdicionandoFormaDePagamento(true)} 
                                    />
                                </>
                            )}
                        </div>
                    </div>

                    {/* Opções de recorrência */}
                    <div className="form-group form-group-checkbox">
                        <label>Recorrente:</label>
                        <input
                            type="checkbox"
                            checked={isRecorrente}
                            onChange={(e) => setIsRecorrente(e.target.checked)}
                        />
                    </div>

                    {isRecorrente && (
                        <>
                            <div className="form-group">
                                <label>Frequência:</label>
                                <select
                                    value={frequenciaRecorrencia}
                                    onChange={(e) => setFrequenciaRecorrencia(e.target.value)}
                                >
                                    <option value="mensal">Mensal</option>
                                    <option value="semanal">Semanal</option>
                                    <option value="anual">Anual</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label>Data de Fim:</label>
                                <input
                                    type="date"
                                    value={dataFimRecorrencia}
                                    onChange={(e) => setDataFimRecorrencia(e.target.value)}
                                />
                            </div>
                        </>
                    )}

                    <div className="form-group">
                        <label>Descrição:</label>
                        <input 
                            type="text" 
                            value={descricao} 
                            onChange={(e) => setDescricao(e.target.value)} 
                            required 
                            className={!validation.descricao ? 'invalid' : ''}
                        />
                    </div>
                    <div className="form-group">
                        <button 
                            className="btn btn-primary" 
                            type="submit" 
                            disabled={!isFormValid}
                        >
                            Salvar Lançamento
                        </button>
                        <button className="btn btn-secondary" type="button" onClick={handleClose}>Cancelar</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default LancamentoModal;
