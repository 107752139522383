import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 
import { faArrowUp, faArrowDown, faMeh } from '@fortawesome/free-solid-svg-icons'; 

const ReceitaCard = ({ selectedDate, userId }) => {
    const [totalReceita, setTotalReceita] = useState(0.0);
    const [totalDespesa, setTotalDespesa] = useState(0.0);
    const [moedaPrimaria, setMoedaPrimaria] = useState('BRL');
    const [crescimento, setCrescimento] = useState(0);
    const [diferencaReceita, setDiferencaReceita] = useState(0.0);

    const [displayedReceita, setDisplayedReceita] = useState(0.0);
    const [displayedSobra, setDisplayedSobra] = useState(0.0);

    useEffect(() => {
        if (!userId || !selectedDate) {
            console.error('User ID or selectedDate is missing');
            return;
        }

        const fetchReceita = async () => {
            try {
                const response = await fetch(`/api/users/${userId}/receita?date=${selectedDate}`);
                const data = await response.json();
                if (response.ok) {
                    setTotalReceita(parseFloat(data.totalReceita) || 0.0);
                    setTotalDespesa(parseFloat(data.totalDespesa) || 0.0);
                    setMoedaPrimaria(data.moedaPrimaria || 'BRL');
                    setCrescimento(data.crescimento || 0);
                    setDiferencaReceita(parseFloat(data.diferencaReceita) || 0.0);
                } else {
                    console.error('Erro ao buscar a receita:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar a receita:', error);
            }
        };

        fetchReceita();
    }, [selectedDate, userId]);

    useEffect(() => {
        const animateValue = (start, end, setter) => {
            const duration = 1000; // duração da animação em milissegundos
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                const value = start + progress * (end - start);
                setter(parseFloat(value).toFixed(2));
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        };

        animateValue(parseFloat(displayedReceita), totalReceita, setDisplayedReceita);
        animateValue(parseFloat(displayedSobra), totalReceita - totalDespesa, setDisplayedSobra);

    }, [totalReceita, totalDespesa]);

    const formatCurrency = (value, currency) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency,
        }).format(value);
    };

    const formatPercentage = (value) => {
        return `${value.toFixed(2)}%`;
    };

    const getCrescimentoIcon = () => {
        if (crescimento > 0) {
            return <FontAwesomeIcon icon={faArrowUp} style={{ color: 'var(--success-color)', marginLeft: '10px', float: 'right' }} />; // Usando a cor de sucesso do sistema
        } else if (crescimento < 0) {
            return <FontAwesomeIcon icon={faArrowDown} style={{ color: 'var(--danger-color)', marginLeft: '10px', float: 'right' }} />; // Usando a cor de perigo do sistema
        } else {
            return <FontAwesomeIcon icon={faMeh} style={{ color: 'var(--secondary-color)', marginLeft: '10px', float: 'right' }} />;
        }
    };

    const getTextStyle = (value) => ({
        color: value >= 0 ? 'var(--success-color)' : 'var(--danger-color)', // Usando as cores de sucesso e perigo do sistema
        fontWeight: 'bold',
        fontSize: '1.2em', // Aumentar o tamanho da fonte
    });

    const getBigTextStyle = (value) => ({
        color: value >= 0 ? 'var(--success-color)' : 'var(--danger-color)', // Usando as cores de sucesso e perigo do sistema
        fontWeight: 'bold',
        fontSize: '1.5em', // Fonte maior para receita e sobra
    });

    return (
        <div className="card primary-inner-card" draggable="true" id="newCard1">
            <div className="card-header">RECEITA</div>
            <div className="card-content">
                <div className="card-row">
                    <div className="card-column">
                        <p>Receita:</p>
                        <p style={getBigTextStyle(displayedReceita)}>
                            {formatCurrency(displayedReceita, moedaPrimaria)}
                        </p>
                    </div>
                    <div className="card-column">
                        <p>Sobra:</p>
                        <p style={getBigTextStyle(displayedSobra)}>
                            {formatCurrency(displayedSobra, moedaPrimaria)}
                        </p>
                    </div>
                </div>
            </div>
            <div className="card-footer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>
                    Crescimento em relação ao mês passado:&nbsp; &nbsp;
                    <span style={getTextStyle(crescimento)}>{formatPercentage(crescimento)}</span>
                </span>
                {getCrescimentoIcon()}
            </div>
        </div>
    );
};

export default ReceitaCard;
