import React, { useState, useEffect } from 'react';
import './EditUserModal.css';

const EditUserModal = ({ isOpen, onClose, onSave, user }) => {
  const [isClosing, setIsClosing] = useState(false);

  // Estados para os campos do formulário
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [pais, setPais] = useState('Brasil');
  const [moeda, setMoeda] = useState('');
  const [idioma, setIdioma] = useState('Português');
  const [moedasDisponiveis, setMoedasDisponiveis] = useState([]);

  // Usando useEffect para preencher os campos quando o modal abre
  useEffect(() => {
    if (user) {
      setNome(user.nome);
      setSobrenome(user.sobrenome);
      setEmail(user.email);
      setTelefone(user.tel_contato);
      setPais(user.pais_padrao);
      setMoeda(user.moeda_primaria);
      setIdioma(user.idioma_padrao);

      // Buscar as moedas disponíveis da API
      fetchMoedas(user.id);
    }
  }, [user]);

  // Fecha o modal quando a tecla Esc é pressionada
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  const fetchMoedas = async (userId) => {
    try {
      const response = await fetch(`/api/moedas_usuario/${userId}`);
      const data = await response.json();
      setMoedasDisponiveis(data);
    } catch (error) {
      console.error('Erro ao buscar moedas:', error);
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('edit-user-modal')) {
      handleClose();
    }
  };

  const handleSave = () => {
    const updatedUser = {
      id: user.id,
      nome,
      sobrenome,
      email,
      tel_contato: telefone,
      pais_padrao: pais,
      moeda_primaria: moeda,
      idioma_padrao: idioma,
    };
    onSave(updatedUser);
  };

  return (
    <div className={`edit-user-modal ${isOpen ? 'show' : ''} ${isClosing ? 'hide' : ''}`} onClick={handleOutsideClick}>
      <div className="edit-user-modal-content">
        <button className="close-btn" onClick={handleClose}>&times;</button>
        <h2>Editar Usuário</h2>
        <div className="form-group">
          <label>Nome:</label>
          <input 
            type="text" 
            value={nome} 
            onChange={(e) => setNome(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label>Sobrenome:</label>
          <input 
            type="text" 
            value={sobrenome} 
            onChange={(e) => setSobrenome(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label>Email:</label>
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label>Telefone:</label>
          <input 
            type="text" 
            value={telefone} 
            onChange={(e) => setTelefone(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label>País:</label>
          <select 
            value={pais} 
            onChange={(e) => setPais(e.target.value)}
          >
            <option value="Brasil">Brasil</option>
            <option value="Irlanda">Irlanda</option>
          </select>
        </div>
        <div className="form-group">
          <label>Moeda:</label>
          <select 
            value={moeda} 
            onChange={(e) => setMoeda(e.target.value)}
          >
            {moedasDisponiveis.map((moeda) => (
              <option key={moeda.id_moeda} value={moeda.moeda}>
                {moeda.moeda}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>Idioma:</label>
          <select 
            value={idioma} 
            onChange={(e) => setIdioma(e.target.value)}
          >
            <option value="Português">Português</option>
            <option value="Inglês">Inglês</option>
          </select>
        </div>
        <div className="form-group">
          <button className="btn btn-primary" onClick={handleSave}>Salvar</button>
          <button className="btn btn-secondary" onClick={handleClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
