import React, { useState } from 'react';
import './MaintenanceModal.css';

const MaintenanceModal = ({ isOpen, onClose, onSave, status, setStatus }) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300); // O tempo deve coincidir com o tempo da animação
  };

  const handleOutsideClick = (e) => {
    if (e.target.className.includes('maintenance-modal')) {
      handleClose();
    }
  };

  return (
    <div className={`maintenance-modal ${isOpen ? 'show' : ''} ${isClosing ? 'hide' : ''}`} onClick={handleOutsideClick}>
      <div className="maintenance-modal-content">
        <button className="close-btn" onClick={handleClose}>&times;</button>
        <h2>Configuração de Manutenção do Servidor</h2>
        <div className="form-group">
          <label>Status do Servidor:</label>
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="online">Servidor Online</option>
            <option value="offline">Servidor em Manutenção</option>
          </select>
        </div>
        <div className="form-group">
          <button className="btn btn-primary" onClick={onSave}>Salvar</button>
          <button className="btn btn-secondary" onClick={handleClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceModal;
