import React, { useEffect, useState, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import './css/CarteirasPieChart.css';

const CarteirasPieChart = ({ userId }) => {
    const [dadosCarteiras, setDadosCarteiras] = useState([]);
    const chartRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId) {
            console.error('User ID não fornecido');
            return;
        }

        const fetchCarteiras = async () => {
            try {
                const response = await fetch(`/api/users/${userId}/carteiras`);
                const data = await response.json();
                if (response.ok) {
                    setDadosCarteiras(data.carteiras || []);
                } else {
                    console.error('Erro ao buscar as carteiras:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar as carteiras:', error);
            }
        };

        fetchCarteiras();
    }, [userId]);

    useEffect(() => {
        const updateLegendColors = () => {
            const chartInstance = chartRef.current;
            if (chartInstance) {
                const isDarkMode = document.body.classList.contains('dark-mode');
                const fontColor = isDarkMode ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color');

                chartInstance.options.plugins.legend.labels.color = fontColor;
                chartInstance.update();
            }
        };

        const observer = new MutationObserver(() => {
            updateLegendColors();
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        return () => {
            observer.disconnect();
        };
    }, []);

    const montarDadosGrafico = () => {
        return {
            labels: dadosCarteiras.map(carteira => carteira.nome),
            datasets: [
                {
                    // Usando saldo_atual em vez de fatura
                    data: dadosCarteiras.map(carteira => carteira.saldo_atual),
                    backgroundColor: dadosCarteiras.map((_, index) => {
                        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
                        return colors[index % colors.length];
                    }),
                    hoverBackgroundColor: dadosCarteiras.map((_, index) => {
                        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
                        return colors[index % colors.length];
                    }),
                },
            ],
        };
    };

    const handleNavigateToCarteiras = () => {
        navigate('/carteiras');
    };

    const options = {
        plugins: {
            legend: {
                position: 'left',
                labels: {
                    usePointStyle: true,
                    color: document.body.classList.contains('dark-mode') ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color'),
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="carteirasPieChartCard">
            <div className="card-header">
                Minhas Carteiras
                <button onClick={handleNavigateToCarteiras} className="btn-ver-carteiras">
                    Ver Carteiras
                </button>
            </div>
            <div className="card-content">
                <Pie ref={chartRef} data={montarDadosGrafico()} options={options} />
            </div>
        </div>
    );
};

export default CarteirasPieChart;
