import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../../assets/styles/main.css';
import './login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://clubedodinheiro.com.br/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
        mode: 'cors',
      });

      if (response.status === 403) {
        throw new Error('O servidor está em manutenção. Tente novamente mais tarde.');
      }

      if (response.status === 409) { // 409 Conflict
        throw new Error('Usuário já está logado.');
      }

      if (response.status === 423) { // 423 Locked
        throw new Error('Usuário bloqueado. Contate o suporte.');
      }

      if (!response.ok) {
        throw new Error('Login ou senha incorretos');
      }

      const data = await response.json();
      
      // Salvando o token, userId e os detalhes do usuário no localStorage
      localStorage.setItem('token', data.token);
      localStorage.setItem('userId', data.userDetails.id);
      localStorage.setItem('userDetails', JSON.stringify({
        userId: data.userDetails.id, // Adicionando o ID do usuário
        nome: data.userDetails.nome,
        sobrenome: data.userDetails.sobrenome,
        email: data.userDetails.email,
        adm: data.userDetails.adm,
        moeda_primaria: data.userDetails.moeda_primaria,
        niv_usuario: data.userDetails.niv_usuario,
        idioma_padrao: data.userDetails.idioma_padrao
    }));
    

      // Enviar notificação de login
      await fetch('https://clubedodinheiro.com.br/api/send-login-notification', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${data.token}`,
        }
      });

      navigate('/dashboard');
    } catch (err) {
      toast.error(err.message); // Exibe a mensagem de erro usando o toast
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword); // Alterna entre mostrar e ocultar a senha
  };

  return (
    <>
      <ToastContainer />
      <header>
        <h1>Login</h1>
      </header>
      <main className="login-container">
        <div className="login-box">
          <h2>Bem-vindo de volta!</h2>
          <p>Acesse sua conta para seguir em direção ao Clube do Dinheiro.</p>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input 
                type="text" 
                id="email" 
                name="email" 
                placeholder="Digite seu email" 
                required 
                value={email} 
                onChange={(e) => setEmail(e.target.value)} 
                autoComplete="email" 
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Senha</label>
              <div className="password-input-wrapper">
                <input 
                  type={showPassword ? 'text' : 'password'} 
                  id="password" 
                  name="password" 
                  placeholder="Digite sua senha" 
                  required 
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)} 
                  autoComplete="current-password" 
                />
                <span onClick={toggleShowPassword} className="password-toggle-icon">
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </div>
            <div className="form-group remember-me">
              <input type="checkbox" id="remember" name="remember" />
              <label htmlFor="remember">Lembre de mim</label>
            </div>
            <button type="submit" className="login-button">Conecte-se</button>
            <a href="/reset-password"><p className="forgot-password"><i className="fas fa-lock"></i> Esqueceu sua senha?</p></a>
          </form>
        </div>
        <div className="extra-text">
          <p className="signup-text">Não tem uma conta? <a href="/signup">Inscreva-se agora</a></p>
          <p className="footer-text">© 2024 Clube do Dinheiro</p>
        </div>
      </main>
    </>
  );
};

export default Login;
