import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import './css/ReceitasDespesasBarChart.css';

const ReceitasDespesasBarChart = ({ userId }) => {
    const [dadosAno, setDadosAno] = useState([]);
    const chartRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId) {
            console.error('User ID não fornecido');
            return;
        }

        const fetchDadosAno = async () => {
            try {
                const response = await fetch(`/api/users/${userId}/receitasDespesasPorAno`);
                const data = await response.json();
                if (response.ok) {
                    setDadosAno(data.receitasDespesasPorAno || []);
                } else {
                    console.error('Erro ao buscar as receitas e despesas por ano:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar as receitas e despesas por ano:', error);
            }
        };

        fetchDadosAno();
    }, [userId]);

    useEffect(() => {
        const updateLegendColors = () => {
            const chartInstance = chartRef.current;
            if (chartInstance) {
                const isDarkMode = document.body.classList.contains('dark-mode');
                const fontColor = isDarkMode ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color');

                chartInstance.options.scales.x.ticks.color = fontColor;
                chartInstance.options.scales.y.ticks.color = fontColor;
                chartInstance.options.plugins.legend.labels.color = fontColor;
                chartInstance.update();
            }
        };

        const observer = new MutationObserver(() => {
            updateLegendColors();
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        return () => {
            observer.disconnect();
        };
    }, []);

    const montarDadosGrafico = () => {
        return {
            labels: dadosAno.map(dado => dado.ano),
            datasets: [
                {
                    label: 'Receitas',
                    data: dadosAno.map(dado => dado.totalReceita),
                    backgroundColor: 'rgba(75, 192, 192, 0.6)', 
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
                {
                    label: 'Despesas',
                    data: dadosAno.map(dado => dado.totalDespesa),
                    backgroundColor: 'rgba(255, 99, 132, 0.6)', 
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                },
            ],
        };
    };

    const handleNavigateToDetails = () => {
        navigate('/detalhes'); 
    };

    const options = {
        scales: {
            x: {
                ticks: {
                    color: document.body.classList.contains('dark-mode') ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color'),
                },
            },
            y: {
                ticks: {
                    color: document.body.classList.contains('dark-mode') ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color'),
                },
            },
        },
        plugins: {
            legend: {
                labels: {
                    color: document.body.classList.contains('dark-mode') ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color'),
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="receitasDespesasBarChartCard">
            <div className="card-header">
                Receitas e Despesas por Ano
                <button onClick={handleNavigateToDetails} className="btn-ver-detalhes">
                    Ver Detalhes
                </button>
            </div>
            <div className="card-content">
                <Bar ref={chartRef} data={montarDadosGrafico()} options={options} />
            </div>
        </div>
    );
};

export default ReceitasDespesasBarChart;
