import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Signup from './pages/Signup/Signup';
import Logout from './pages/Logout/Logout';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ChangePassword from './pages/ResetPassword/ChangePassword';
import Profile from './pages/Profile/Profile'; 
import ProtectedRoute from './components/ProtectedRoute';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import FloatingButton from './components/FloatingButton/FloatingButton';
import Config from './pages/Config/Config'; 
import Admin from './pages/Admin/Admin';
import Categorias from './pages/Categorias/Categorias';
import FormasPagamento from './pages/FormaPagamento/FormasPagamento';
import Lancamentos from './pages/Lancamentos/Lancamentos';

function App() {
  const [selectedDate, setSelectedDate] = useState(localStorage.getItem('selectedDate') || '');

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <div className="page-container">
                  <Header onDateChange={handleDateChange} />
                  <FloatingButton />
                  <Dashboard selectedDate={selectedDate} />
                  <Footer />
                </div>
              </ProtectedRoute>
            } 
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/reset-password/:hash" element={<ChangePassword />} />
          <Route 
            path="/profile" 
            element={
              <ProtectedRoute>
                <div className="page-container">
                  <Header onDateChange={handleDateChange} />
                  <FloatingButton />
                  <Profile />
                  <Footer />
                </div>
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/config" 
            element={
              <ProtectedRoute>
                <div className="page-container">
                  <Header onDateChange={handleDateChange} />
                  <FloatingButton />
                  <Config />
                  <Footer />
                </div>
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute>
                <div className="page-container">
                  <Header onDateChange={handleDateChange} />
                  <FloatingButton />
                  <Admin />
                  <Footer />
                </div>
              </ProtectedRoute>
            } 
          />
          <Route 
              path="/categorias" 
              element={
                  <ProtectedRoute>
                      <div className="page-container">
                          <Header />
                          <Categorias />
                          <Footer />
                      </div>
                  </ProtectedRoute>
              } 
          />
          <Route 
              path="/formaspagamento" 
              element={
                  <ProtectedRoute>
                      <div className="page-container">
                          <Header />
                          <FormasPagamento />
                          <Footer />
                      </div>
                  </ProtectedRoute>
              } 
          />
          <Route 
              path="/lancamentos" 
              element={
                  <ProtectedRoute>
                      <div className="page-container">
                      <Header onDateChange={handleDateChange} />
                          <Lancamentos selectedDate={selectedDate} />
                          <Footer />
                      </div>
                  </ProtectedRoute>
              } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
