import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/styles/main.css';
import './header.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faUser, faCog, faQuestionCircle, faSignOutAlt, faChevronRight, faUserShield } from '@fortawesome/free-solid-svg-icons';
import md5 from 'md5';
import NovaCarteiraModal from '../../modals/NovaCarteiraModal';
import NovoCartaoCreditoModal from '../../modals/NovoCartaoCreditoModal';
import TransferenciaModal from '../../modals/TransferenciaModal';
import LancamentoModal from '../../modals/LancamentoModal';  // Importa o modal de lançamento
import ErrorBoundary from '../../utils/ErrorBoundary';

const Header = ({ onDateChange }) => {
  const [userDetails, setUserDetails] = useState({
    email: '',
    nome: '',
    sobrenome: '',
    isAdmin: false,
    gravatarUrl: '',
  });

  const handleClick = (event) => {
    event.preventDefault(); // Impede o clique de funcionar
   // console.log('Link clicado, mas a navegação foi impedida');
  };


  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreditCardOpen, setIsCreditCardOpen] = useState(false);
  const [isTransferenciaOpen, setIsTransferenciaOpen] = useState(false);

  // Novo estado para o modal de lançamento
  const [isLancamentoOpen, setIsLancamentoOpen] = useState(false);
  const [tipoLancamentoInicial, setTipoLancamentoInicial] = useState(''); // Controla o tipo de lançamento (Receita ou Despesa)

  const dropdownRef = useRef(null);

  // Funções para abrir e fechar o modal de Transferência
  const handleOpenTransferenciaModal = () => setIsTransferenciaOpen(true);
  const handleCloseTransferenciaModal = () => setIsTransferenciaOpen(false);

  // Função para abrir o modal de lançamento com o tipo pré-selecionado
  const handleOpenLancamentoModal = (tipo) => {
    setTipoLancamentoInicial(tipo); // Define o tipo de lançamento (Receita ou Despesa)
    setIsLancamentoOpen(true); // Abre o modal de lançamento
  };

  const handleCloseLancamentoModal = () => setIsLancamentoOpen(false);

  useEffect(() => {
    const userDetailsFromStorage = JSON.parse(localStorage.getItem('userDetails'));
    const savedTheme = localStorage.getItem('theme') || 'light';
    
    if (userDetailsFromStorage) {
      const gravatarUrl = `https://www.gravatar.com/avatar/${md5(userDetailsFromStorage.email.trim().toLowerCase())}?s=80`;
      const userId = localStorage.getItem('userId');
  
      setUserDetails({
        email: userDetailsFromStorage.email,
        nome: userDetailsFromStorage.nome,
        sobrenome: userDetailsFromStorage.sobrenome,
        isAdmin: userDetailsFromStorage.adm === 1,
        gravatarUrl,
      });
  
      fetch(`/api/users/${userId}/updateDate`)
        .then(response => response.json())
        .then(data => {
          if (data.selectedDate) {
            setSelectedDate(data.selectedDate); // Carrega a data do arquivo
            onDateChange(data.selectedDate); // Atualiza o estado global
          } else {
            console.error('Data não encontrada no arquivo do usuário.');
          }
        })
        .catch(error => console.error('Erro ao carregar a data do usuário:', error));
    }
  
    document.body.classList.toggle('dark-mode', savedTheme === 'dark');
    setIsDarkMode(savedTheme === 'dark');
  }, [onDateChange]);

  const handleDateChange = (event) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);

    const userId = localStorage.getItem('userId');
    if (userId) {
      fetch(`/api/users/${userId}/updateDate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ selectedDate: newDate }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Falha ao salvar a data.');
        }
        localStorage.setItem('selectedDate', newDate);
        return response.json();
      })
      .then(data => {
        if (!data.success) {
          console.error("Erro ao salvar a data selecionada:", data.message);
        } else {
          onDateChange(newDate); // Chama a função para atualizar o estado global
        }
      })
      .catch(error => {
        console.error('Erro ao salvar a data no arquivo do usuário:', error);
      });
    }
  };

  const formatName = (nome, sobrenome) => `${nome} ${sobrenome}`;

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  const toggleTheme = async () => {
    const newTheme = isDarkMode ? 'light' : 'dark';
    document.body.classList.toggle('dark-mode', newTheme === 'dark');
    setIsDarkMode(newTheme === 'dark');
    localStorage.setItem('theme', newTheme);

    try {
      const userId = localStorage.getItem('userId');
      const response = await fetch(`/api/user_data/${userId}.json`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ theme: newTheme }),
      });

      if (!response.ok) {
        throw new Error('Erro ao atualizar o tema do usuário');
      }
      console.log('Tema atualizado com sucesso no servidor');
    } catch (error) {
      console.error('Erro ao salvar o tema no arquivo do usuário:', error);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  // Funções para controlar o modal
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  // Função para abrir o modal de Cartão de Crédito
  const handleOpenCreditCardModal = () => setIsCreditCardOpen(true);

  // Função para fechar o modal de Cartão de Crédito
  const handleCloseCreditCardModal = () => setIsCreditCardOpen(false);

  return (
    <header>
      <div className="header-top">
        <div className="header-left">
          <Link to="/dashboard">
            <img src="/images/logo.png" alt="Clube do Dinheiro Logo" className="logo" />
          </Link>
          <h1>Clube do Dinheiro</h1>
        </div>
        <div className="header-right">
          <div className="date-range">
            <label htmlFor="month-year">Período:</label>
            <input
              type="month"
              id="month-year"
              className="date-input"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
          <select id="language-select" className="language-select">
            <option value="pt">Português</option>
            <option value="en">Inglês</option>
          </select>
          <div className="dropdown" ref={dropdownRef}>
            <img
              src={userDetails.gravatarUrl || "/images/default-avatar.png"}
              alt="Avatar do Usuário"
              className="avatar"
              id="avatar"
              onClick={toggleDropdown}
            />
            {isDropdownOpen && (
              <div className={`dropdown-content ${isDropdownOpen ? 'show' : ''}`} id="dropdown-content">
                <p><strong>{formatName(userDetails.nome, userDetails.sobrenome)}</strong></p>
                <Link to="/profile" onClick={closeDropdown}>
                  <FontAwesomeIcon icon={faUser} style={{ marginRight: '10px' }} /> Perfil
                </Link>
                <Link to="/config" onClick={closeDropdown}>
                  <FontAwesomeIcon icon={faCog} style={{ marginRight: '10px' }} /> Configurações
                </Link>
                <Link to="https://docs.oclubedodinheiro.com.br/" target="_blank" rel="noopener noreferrer" onClick={closeDropdown}>
                  <FontAwesomeIcon icon={faQuestionCircle} style={{ marginRight: '10px' }} /> Ajuda e Suporte
                </Link>
                {userDetails.isAdmin && (
                  <Link to="/admin" onClick={closeDropdown}>
                    <FontAwesomeIcon icon={faUserShield} style={{ marginRight: '10px' }} /> Administrador
                  </Link>
                )}
                <hr />
                <Link to="/logout" onClick={closeDropdown}>
                  <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '10px' }} /> Sair
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="menu-container">
        <nav className="menu-horizontal">
          <ul>
            <li>
              <Link to="#" onClick={handleClick}>
                Principal 
              </Link>
              <ul className="submenu">
                <li><Link to="/dashboard">Painel</Link></li>
              </ul>
            </li>
            <li>
              <Link to="#" onClick={handleClick}>
                Lançamentos
              </Link>
              <ul className="submenu">
                <li>
                  <Link to="#" onClick={handleClick}>
                    Carteiras <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <ul className="submenu">
                    <li><Link onClick={handleOpenModal}>Nova Carteira</Link></li>
                    <li><Link onClick={handleOpenCreditCardModal}>Novo Cartão</Link></li>
                    <li><Link onClick={handleOpenTransferenciaModal}>Transferências</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="#" onClick={handleClick}>
                    Lançamento <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <ul className="submenu">
                    <li><Link onClick={() => handleOpenLancamentoModal('Receita')}>Receita</Link></li>
                    <li><Link onClick={() => handleOpenLancamentoModal('Despesa')}>Despesa</Link></li>
                    <li><Link onClick={() => handleOpenLancamentoModal('Despesa em Cartão')}>Despesa em Cartão</Link></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#" onClick={handleClick}>
                Metas
              </Link>
              <ul className="submenu">
                <li><Link to="#">Criar Meta</Link></li>
                <li><Link to="#">Progresso das Metas</Link></li>
                <li><Link to="#">Meta de Carteira</Link></li>
              </ul>
            </li>
            <li>
              <Link to="#" onClick={handleClick}>
                Relatórios
              </Link>
              <ul className="submenu">
                <li><Link to="#">Relatórios</Link></li>
              </ul>
            </li>
            <li>
              <Link to="#" onClick={handleClick}>
                Configurações 
              </Link>
              <ul className="submenu">
                <li><Link to="/categorias">Categorias</Link></li>
                <li><Link to="/formaspagamento">Forma de Pagamento</Link></li>
                <li><Link to="/config">Alertas e Notificações</Link></li>
                <li><Link to="/profile">Configuração da Conta</Link></li>
                <li><Link to="https://docs.oclubedodinheiro.com.br/">Ajuda e Suporte</Link></li>
              </ul>
            </li>
            <li className="menu-right no-hover">
                <button id="toggle-dark-mode" onClick={toggleTheme}>
                    <FontAwesomeIcon icon={isDarkMode ? faSun : faMoon} id="theme-icon" />
                </button>
            </li>
          </ul>
        </nav>
      </div>

      {/* Modais encapsulados em ErrorBoundary */}
      <ErrorBoundary>
        {isModalOpen && <NovaCarteiraModal isOpen={isModalOpen} onClose={handleCloseModal} />}
        {isCreditCardOpen && <NovoCartaoCreditoModal isOpen={isCreditCardOpen} onClose={handleCloseCreditCardModal} />}
        {isTransferenciaOpen && <TransferenciaModal isOpen={isTransferenciaOpen} onClose={handleCloseTransferenciaModal} />}
        {isLancamentoOpen && <LancamentoModal isOpen={isLancamentoOpen} onClose={handleCloseLancamentoModal} tipoLancamentoInicial={tipoLancamentoInicial} />}
      </ErrorBoundary>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </header>
  );
};

export default Header;
