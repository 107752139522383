import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../assets/styles/main.css';
import './reset_Password.css';

const ResetPassword = () => {
  const [email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/reset-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        toast.success('Link de redefinição enviado para o seu email.');
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || 'Erro ao enviar link de redefinição.');
      }
    } catch (error) {
      console.error('Erro ao realizar a requisição:', error);
      toast.error('Erro ao realizar a requisição: ' + error.message);
    }
  };

  return (
    <>
    <header>
    <h1>Recuperar sua senha</h1>
  </header>
    <main className="reset-password-container">
      <div className="reset-password-box">
        <ToastContainer /> {/* Componente para exibir os toasts */}
        <h2>Recuperar sua senha</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              placeholder="Digite seu email" 
              required 
              autoComplete="email" 
              value={email}
              onChange={handleChange} 
            />
          </div>
          <button type="submit" className="reset-password-button">Enviar link de redefinição</button>
        </form>
      </div>
      <div className="extra-text">
        <p className="login-text">Lembrou sua senha? <Link to="/login">Entrar</Link></p>
      </div>
    </main>
    </>
  );
};

export default ResetPassword;
