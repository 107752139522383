import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faMeh } from '@fortawesome/free-solid-svg-icons';

const DespesaCard = ({ selectedDate, userId }) => {
    const [totalDespesa, setTotalDespesa] = useState(0.0);
    const [moedaPrimaria, setMoedaPrimaria] = useState('BRL');
    const [endividamento, setEndividamento] = useState(0);
    const [diferencaDespesa, setDiferencaDespesa] = useState(0.0);

    const [displayedDespesa, setDisplayedDespesa] = useState(0.0);

    useEffect(() => {
        if (!userId || !selectedDate) {
            console.error('User ID or selectedDate is missing');
            return;
        }
    
        const fetchDespesa = async () => {
            try {
                const response = await fetch(`/api/users/${userId}/despesa?date=${selectedDate}`);
                const data = await response.json();
                if (response.ok) {
                    const totalDespesa = parseFloat(data.totalDespesa) || 0.0;
                    const totalReceita = parseFloat(data.totalReceita) || 0.0;
    
                    setTotalDespesa(totalDespesa);
                    setMoedaPrimaria(data.moedaPrimaria || 'BRL');
                    setDiferencaDespesa(parseFloat(data.diferencaDespesa) || 0.0);
    
                    // Atualização na lógica de cálculo do endividamento
                    const endividamento = totalReceita > 0 ? (totalDespesa / totalReceita) * 100 : totalDespesa > 0 ? 100 : 0;
                    setEndividamento(endividamento);
                } else {
                    console.error('Erro ao buscar a despesa:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar a despesa:', error);
            }
        };
    
        fetchDespesa();
    }, [selectedDate, userId]);
    

    useEffect(() => {
        const animateValue = (start, end, setter) => {
            const duration = 1000; // duração da animação em milissegundos
            let startTimestamp = null;
            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const progress = Math.min((timestamp - startTimestamp) / duration, 1);
                const value = start + progress * (end - start);
                setter(parseFloat(value).toFixed(2));
                if (progress < 1) {
                    window.requestAnimationFrame(step);
                }
            };
            window.requestAnimationFrame(step);
        };

        animateValue(parseFloat(displayedDespesa), totalDespesa, setDisplayedDespesa);

    }, [totalDespesa]);

    const formatCurrency = (value, currency) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency,
        }).format(value);
    };

    const formatPercentage = (value) => {
        return `${value.toFixed(2)}%`;
    };

    const getEndividamentoIcon = () => {
        if (endividamento > 30) {
            return <FontAwesomeIcon icon={faArrowUp} style={{ color: 'var(--danger-color)', marginLeft: '10px', float: 'right' }} />; // Usando a cor de perigo do sistema
        } else {
            return <FontAwesomeIcon icon={faArrowDown} style={{ color: 'var(--success-color)', marginLeft: '10px', float: 'right' }} />; // Usando a cor de sucesso do sistema
        }
    };

    const getBigTextStyle = () => ({
        color: 'var(--danger-color)', // Usando a cor de perigo para despesa
        fontWeight: 'bold',
        fontSize: '1.5em', // Fonte maior para despesa
    });

    const getTextStyle = (value) => ({
        color: value > 30 ? 'var(--danger-color)' : 'var(--success-color)', // Usando as cores de perigo e sucesso do sistema
        fontWeight: 'bold',
        fontSize: '1.2em', // Aumentar o tamanho da fonte
    });

    return (
        <div className="card danger-inner-card" draggable="true" id="newCard2">
            <div className="card-header">DESPESA</div>
            <div className="card-content">
                <div className="card-row">
                    <div className="card-column">
                        <p>Despesa:</p>
                        <p style={getBigTextStyle()}>
                            {formatCurrency(displayedDespesa, moedaPrimaria)}
                        </p>
                    </div>
                </div>
            </div>
            <div className="card-footer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span>
                    Endividamento mês atual:&nbsp; &nbsp;
                    <span style={getTextStyle(endividamento)}>{formatPercentage(endividamento)}</span>
                </span>
                {getEndividamentoIcon()}
            </div>
        </div>
    );
};

export default DespesaCard;
