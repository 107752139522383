import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './NovoCartaoCreditoModal.css'; // Seguindo o padrão de estilo

const NovaCartaoCreditoModal = ({ isOpen, onClose }) => {
  const [nome, setNome] = useState('');
  const [limiteCredito, setLimiteCredito] = useState('');
  const [saldoDisponivel, setSaldoDisponivel] = useState('');
  const [dataValidade, setDataValidade] = useState('');
  const [dataVencimentoFatura, setDataVencimentoFatura] = useState('');
  const [dataViradaFatura, setDataViradaFatura] = useState('');
  const [moeda, setMoeda] = useState('BRL'); // Valor inicial padrão como BRL
  const [ativa, setAtiva] = useState(true); // Inicialize como true
  const [moedasDisponiveis, setMoedasDisponiveis] = useState([]);
  const [isClosing, setIsClosing] = useState(false);
  const [validation, setValidation] = useState({
    nome: true,
    limiteCredito: true,
    saldoDisponivel: true,
    dataValidade: true,
    dataVencimentoFatura: true,
    dataViradaFatura: true,
    moeda: true,
  });
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const userId = localStorage.getItem('userId');
      fetch(`/api/users/${userId}/moedas`)
        .then((res) => res.json())
        .then((data) => setMoedasDisponiveis(data))
        .catch((err) => console.error('Erro ao buscar moedas:', err));
    }
  }, [isOpen]);

  const validateForm = () => {
    const validationState = {
      nome: !!nome,
      limiteCredito: !!limiteCredito,
      saldoDisponivel: !!saldoDisponivel,
      dataValidade: !!dataValidade,
      dataVencimentoFatura: !!dataVencimentoFatura,
      dataViradaFatura: !!dataViradaFatura,
      moeda: !!moeda,
    };
    setValidation(validationState);
    return Object.values(validationState).every(Boolean);
  };

  useEffect(() => {
    setIsFormValid(validateForm());
  }, [nome, limiteCredito, saldoDisponivel, dataValidade, dataVencimentoFatura, dataViradaFatura, moeda]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userId = localStorage.getItem('userId');

    if (!validateForm()) {
      toast.error('Preencha todos os campos obrigatórios.');
      return;
    }

    fetch(`/api/users/${userId}/cartoes-credito`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        nome,
        limite_credito: limiteCredito,
        saldo_disponivel: saldoDisponivel,
        data_validade: dataValidade,
        data_vencimento_fatura: dataVencimentoFatura,
        data_virada_fatura: dataViradaFatura,
        moeda,
        ativa: ativa ? 1 : 0,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === 'Cartão de crédito cadastrado com sucesso.') {
          toast.success('Cartão de crédito cadastrado com sucesso!', {
            autoClose: 5000,
          });
          setTimeout(() => {
            handleClose();
          }, 10);
        }
      })
      .catch((err) => {
        console.error('Erro ao cadastrar cartão de crédito:', err);
        toast.error('Erro ao cadastrar cartão de crédito.');
      });
  };

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('nova-cartao-credito-modal')) {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, 300);
  };

  return (
    <>
      <div className={`nova-cartao-credito-modal ${isOpen ? 'show' : ''} ${isClosing ? 'hide' : ''}`} onClick={handleOutsideClick}>
        <div className="nova-cartao-credito-modal-content">
          <button className="close-btn" onClick={handleClose}>&times;</button>
          <h2>Cadastrar Novo Cartão de Crédito</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Nome do Cartão:</label>
              <input
                type="text"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                className={!validation.nome ? 'invalid' : ''}
                required
              />
            </div>
            <div className="form-group">
              <label>Limite de Crédito:</label>
              <input
                type="number"
                value={limiteCredito}
                onChange={(e) => setLimiteCredito(e.target.value)}
                className={!validation.limiteCredito ? 'invalid' : ''}
                required
              />
            </div>
            <div className="form-group">
              <label>Saldo Disponível:</label>
              <input
                type="number"
                value={saldoDisponivel}
                onChange={(e) => setSaldoDisponivel(e.target.value)}
                className={!validation.saldoDisponivel ? 'invalid' : ''}
                required
              />
            </div>
            <div className="form-group">
              <label>Data de Validade:</label>
              <input
                type="date"
                value={dataValidade}
                onChange={(e) => setDataValidade(e.target.value)}
                className={!validation.dataValidade ? 'invalid' : ''}
                required
              />
            </div>
            <div className="form-group">
              <label>Data de Vencimento da Fatura:</label>
              <input
                type="date"
                value={dataVencimentoFatura}
                onChange={(e) => setDataVencimentoFatura(e.target.value)}
                className={!validation.dataVencimentoFatura ? 'invalid' : ''}
                required
              />
            </div>
            <div className="form-group">
              <label>Data de Virada da Fatura:</label>
              <input
                type="date"
                value={dataViradaFatura}
                onChange={(e) => setDataViradaFatura(e.target.value)}
                className={!validation.dataViradaFatura ? 'invalid' : ''}
                required
              />
            </div>
            <div className="form-group">
              <label>Moeda:</label>
              <select
                value={moeda}
                onChange={(e) => setMoeda(e.target.value)}
                className={!validation.moeda ? 'invalid' : ''}
                required
              >
                <option value="">Selecione a moeda</option>
                {moedasDisponiveis.map((m) => (
                  <option key={m.id_moeda} value={m.moeda}>
                    {m.moeda}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>Ativa:</label>
              <input
                type="checkbox"
                checked={ativa}
                onChange={(e) => setAtiva(e.target.checked)}
              />
            </div>
            <div className="form-group">
              <button className="btn btn-primary" type="submit" disabled={!isFormValid}>
                Cadastrar
              </button>
              <button className="btn btn-secondary" type="button" onClick={handleClose}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default NovaCartaoCreditoModal;
