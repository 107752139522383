import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './css/ReceitasTable.css';

const ReceitasTable = ({ userId, selectedDate }) => {
    const [receitas, setReceitas] = useState([]);
    const [nivelUsuario, setNivelUsuario] = useState(1); // Valor padrão como 1
    const navigate = useNavigate();

    // Função para buscar o nível do usuário
    const fetchUserLevel = async () => {
        try {
            const response = await fetch(`/api/users_nivel/${userId}`);
            const data = await response.json();
            if (response.ok) {
                setNivelUsuario(data.nivel); // Armazena o nível do usuário
            } else {
                console.error('Erro ao buscar o nível do usuário:', data.message);
            }
        } catch (error) {
            console.error('Erro ao buscar o nível do usuário:', error);
        }
    };

    // Função para buscar as receitas
    const fetchReceitas = async () => {
        if (!userId || !selectedDate) {
            console.error('User ID ou data não fornecidos');
            return;
        }

        // Limpa as receitas ao começar a buscar novos dados
        setReceitas([]);

        try {
            const response = await fetch(`/api/users/${userId}/receitas?selectedDate=${selectedDate}`);
            const data = await response.json();
            if (response.ok) {
                if (data.receitas && data.receitas.length > 0) {
                    setReceitas(data.receitas);
                } else {
                    setReceitas([]); // Se não houver receitas, limpa o estado
                }
            } else {
                console.error('Erro ao buscar as receitas:', data.message);
                setReceitas([]); // Limpa o estado se houver erro
            }
        } catch (error) {
            console.error('Erro ao buscar as receitas:', error);
            setReceitas([]); // Limpa o estado em caso de erro
        }
    };

    useEffect(() => {
        fetchUserLevel();
        fetchReceitas();
    }, [userId, selectedDate]); // Dependências para atualizar os dados ao mudar userId ou selectedDate

    const handleNavigateToReceitas = () => {
        navigate('/lancamentos', { state: { tipoLancamento: 'Receita' } });
    };

    const formatCurrency = (value, currency) => {
        try {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency }).format(value);
        } catch (e) {
            console.error('Invalid currency code:', currency);
            return value;
        }
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="receitasTableCard">
            <div className="card-header">
                Lançamentos de Receitas
                <button onClick={handleNavigateToReceitas} className="btn-ver-receitas">
                    Ver Detalhes
                </button>
            </div>
            <div className="card-content">
                {nivelUsuario === 1 && receitas.length > 3 && (
                    <div className="message-overlay">
                        <p>Para ver mais receitas, assine um plano.</p>
                    </div>
                )}
                {receitas.length === 0 ? (
                    <p className="no-receitas-message">Nenhuma receita encontrada para este mês.</p>
                ) : (
                    <table className={`receitas-table ${nivelUsuario === 1 && receitas.length > 3 ? 'blurred' : ''}`}>
                        <thead>
                            <tr>
                                <th>Data</th>
                                <th>Descrição</th>
                                <th>Valor</th>
                                <th>Carteira</th>
                                <th>Forma de Pagamento</th>
                            </tr>
                        </thead>
                        <tbody>
                            {receitas.map((receita, index) => (
                                <tr key={index} className={index >= 3 && nivelUsuario === 1 ? 'embasado' : ''}>
                                    <td>{new Date(receita.DATA).toLocaleDateString('pt-BR')}</td>
                                    <td>{receita.DESCRICAO}</td>
                                    <td>{formatCurrency(receita.VALOR, receita.moeda)}</td>
                                    <td>{receita.CARTEIRA}</td>
                                    <td>{receita.FORMA_DE_PAGAMENTO}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default ReceitasTable;
