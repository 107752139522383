import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCreditCard, faMoneyBillWave, faWallet, faBarcode, faMobileAlt,
    faUniversity, faMoneyCheckAlt, faMoneyCheck, faDollarSign, faEdit, faTrash
} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './formaspagamento.css';


const FormasPagamento = () => {
    const [formasPagamento, setFormasPagamento] = useState([]);
    const [newFormaPagamento, setNewFormaPagamento] = useState('');
    const [editFormaPagamento, setEditFormaPagamento] = useState(null);
    const [editNomeFormaPagamento, setEditNomeFormaPagamento] = useState('');
    const [formaPagamentoParaDeletar, setFormaPagamentoParaDeletar] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal de confirmação de deleção
    const [modalVisible, setModalVisible] = useState(false); // Modal de edição
    const userId = localStorage.getItem('userId');

    // Mapeamento de ícones por tipo de forma de pagamento (sem acentos)
    const paymentIconMap = {
        'pix': faMoneyBillWave,
        'transferencia instantanea': faMoneyBillWave,
        'chave pix': faMoneyBillWave,
        'boleto': faBarcode,
        'fatura': faBarcode,
        'cartao de credito': faCreditCard,
        'credito': faCreditCard,
        'pagamento parcelado': faCreditCard,
        'cartao de debito': faMoneyCheckAlt,
        'debito': faMoneyCheckAlt,
        'dinheiro': faDollarSign,
        'cedula': faDollarSign,
        'pagamento em especie': faDollarSign,
        'transferencia bancaria': faUniversity,
        'ted': faUniversity,
        'doc': faUniversity,
        'paypal': faMobileAlt,
        'picpay': faWallet,
        'google pay': faMobileAlt,
        'apple pay': faMobileAlt,
        'vale alimentacao': faMoneyCheck,
        'vale refeicao': faMoneyCheckAlt,
        'transferencia internacional': faUniversity,
        'criptomoedas': faWallet,
        'qr code': faMobileAlt,
        'carteira digital': faWallet
    };

    // Função para buscar formas de pagamento
    const fetchFormasPagamento = async () => {
        try {
            const response = await axios.get(`/api/users/${userId}/formaspagamento`);
            setFormasPagamento(response.data.formaspagamento);
        } catch (error) {
            toast.error('Erro ao carregar formas de pagamento.');
        }
    };

    useEffect(() => {
        fetchFormasPagamento();
    }, [userId]);

    // Função para adicionar nova forma de pagamento
    const handleAddFormaPagamento = async () => {
        if (!newFormaPagamento) {
            toast.error('Nome da forma de pagamento é obrigatório.');
            return;
        }

        try {
            const response = await axios.post(`/api/users/${userId}/formaspagamento`, {
                nome_formapagamento: newFormaPagamento
            });

            if (response.data.message === 'Forma de pagamento adicionada com sucesso.') {
                toast.success('Forma de pagamento adicionada com sucesso!');
                setNewFormaPagamento('');
                fetchFormasPagamento();
            }
        } catch (error) {
            toast.error('Erro ao adicionar forma de pagamento.');
        }
    };

    // Função para editar forma de pagamento
    const handleEditFormaPagamento = async () => {
        if (!editNomeFormaPagamento) {
            toast.error('Nome da forma de pagamento é obrigatório.');
            return;
        }

        try {
            const response = await axios.put(`/api/users/${userId}/formaspagamento/${editFormaPagamento.id_formapagamento}`, {
                nome_formapagamento: editNomeFormaPagamento
            });

            if (response.data.message === 'Forma de pagamento editada com sucesso.') {
                toast.success('Forma de pagamento editada com sucesso!');
                fetchFormasPagamento();
                closeEditModal();
            }
        } catch (error) {
            toast.error('Erro ao editar forma de pagamento.');
        }
    };

    // Função para deletar forma de pagamento
    const handleDeleteFormaPagamento = async () => {
        if (!formaPagamentoParaDeletar) return;

        try {
            const response = await axios.delete(`/api/users/${userId}/formaspagamento/${formaPagamentoParaDeletar.id_formapagamento}`);
            if (response.data.message === 'Forma de pagamento deletada com sucesso.') {
                toast.success('Forma de pagamento deletada com sucesso!');
                fetchFormasPagamento();
            }
        } catch (error) {
            toast.error('Erro ao deletar forma de pagamento.');
        }
        closeModal();
    };

    // Função para abrir o modal de edição
    const openEditModal = (forma) => {
        setEditFormaPagamento(forma);
        setEditNomeFormaPagamento(forma.nome_formapagamento);
        setModalVisible(true);
    };

    // Função para fechar o modal de edição
    const closeEditModal = () => {
        setModalVisible(false);
        setEditFormaPagamento(null);
    };

    // Função para abrir o modal de confirmação de deleção
    const openModal = (forma) => {
        setFormaPagamentoParaDeletar(forma);
        setIsModalOpen(true);
    };

    // Função para fechar o modal de confirmação de deleção
    const closeModal = () => {
        setIsModalOpen(false);
        setFormaPagamentoParaDeletar(null);
    };

        // Função para obter o ícone da forma de pagamento
        const getPaymentIcon = (nomeFormaPagamento) => {
            // Normaliza o nome para remover acentos e converter para minúsculas
            const normalizedPagamento = nomeFormaPagamento
                .trim()
                .toLowerCase()
                .normalize('NFD') // Decompõe o texto em caracteres base e acentos
                .replace(/\p{Diacritic}/gu, ""); // Remove os acentos (diacríticos)
            
            // Faz o mapeamento usando o nome normalizado
            return paymentIconMap[normalizedPagamento] || faWallet;
        };


    return (
        <div className="formaspagamento-container">
            <h2>Adicionar Nova Forma de Pagamento</h2>
            <div className="add-formapagamento-form">
                <input
                    type="text"
                    value={newFormaPagamento}
                    onChange={(e) => setNewFormaPagamento(e.target.value)}
                    placeholder="Nome da Forma de Pagamento"
                />
                <button onClick={handleAddFormaPagamento}>Adicionar</button>
            </div>

            <div className="formaspagamento-section">
                <h3>Formas de Pagamento</h3>
                {formasPagamento.length > 0 ? (
                    <ul className="formaspagamento-list">
                        {formasPagamento.map((forma) => (
                            <li key={forma.id_formapagamento}>
                                <div className="formaspagamento-text">
                                    <FontAwesomeIcon icon={getPaymentIcon(forma.nome_formapagamento)} className="formaspagamento-icon" />
                                    <span>{forma.nome_formapagamento}</span>
                                </div>
                                <div className="icon-group">
                                    <button className="icon-btn icoeditar" onClick={() => openEditModal(forma)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <button className="icon-btn" onClick={() => openModal(forma)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="empty-message">Nenhuma forma de pagamento encontrada.</p>
                )}
            </div>

            {/* Modal de confirmação de deleção */}
            {isModalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content">
                        <h3>Confirmar Exclusão</h3>
                        <p>Tem certeza que deseja excluir a forma de pagamento "{formaPagamentoParaDeletar?.nome_formapagamento}"?</p>
                        <div className="modal-actions">
                            <button className="btn-confirm" onClick={handleDeleteFormaPagamento}>Excluir</button>
                            <button className="btn-cancel" onClick={closeModal}>Cancelar</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal de Edição */}
            {modalVisible && (
                <div className="modal-overlay" onClick={(e) => {
                    // Verifica se o clique foi no overlay, e não no conteúdo do modal
                    if (e.target.classList.contains('modal-overlay')) {
                        closeEditModal();
                    }
                }}>
                    <div className="modal-content">
                        <h3>Editar Forma de Pagamento</h3>
                        <label>Nome da Forma de Pagamento</label>
                        <input
                            type="text"
                            value={editNomeFormaPagamento}
                            onChange={(e) => setEditNomeFormaPagamento(e.target.value)}
                        />
                        <div className="modal-actions">
                            <button onClick={handleEditFormaPagamento}>Salvar</button>
                            <button onClick={closeEditModal}>Cancelar</button>
                        </div>
                    </div>
                </div>
            )}


            <ToastContainer />
        </div>
    );
};

export default FormasPagamento;
