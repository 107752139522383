import React from 'react';
import '../../assets/styles/main.css';

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 Clube do Dinheiro. Todos os direitos reservados.</p>
    </footer>
  );
};

export default Footer;
