import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot } from '@fortawesome/free-solid-svg-icons';
import './css/SerenaBot.css';

const SerenaBot = ({ userId, selectedDate }) => {
    const [mensagens, setMensagens] = useState([]);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [typing, setTyping] = useState(false);
    const [nivelUsuario, setNivelUsuario] = useState(null); // Inicia como null para esperar a consulta

    useEffect(() => {
        if (!userId || !selectedDate) {
            console.error('User ID ou Data não fornecidos');
            return;
        }

        const fetchMensagens = async () => {
            try {
                setMensagens([]); 
                setCurrentMessageIndex(0); 
                setTyping(false);

                const response = await fetch(`/api/users/${userId}/serenaMessages?date=${selectedDate}`);
                const data = await response.json();
                if (response.ok) {
                    setMensagens(data.mensagens || []);
                } else {
                    console.error('Erro ao buscar mensagens do robô:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar mensagens do robô:', error);
            }
        };

        const fetchUserLevel = async () => {
            try {
                const response = await fetch(`/api/users_nivel/${userId}`);
                const data = await response.json();
                if (response.ok) {
                    setNivelUsuario(data.nivel); // Armazena o nível do usuário
                } else {
                    console.error('Erro ao buscar o nível do usuário:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar o nível do usuário:', error);
            }
        };
        
        fetchUserLevel();
        fetchMensagens();
    }, [userId, selectedDate]);

    useEffect(() => {
        if (nivelUsuario > 1 && mensagens.length > 0 && currentMessageIndex < mensagens.length) {
            displayNextMessage();
        }
    }, [mensagens, currentMessageIndex, nivelUsuario]);

    const displayNextMessage = async () => {
        setTyping(true);
        await new Promise(resolve => setTimeout(resolve, 5000)); 
        setTyping(false);
        
        setCurrentMessageIndex(prevIndex => prevIndex + 1);
    };
    
    const getMessageClass = (tipo) => {
        switch (tipo) {
            case 'positivo':
                return 'serena-message positive-message';
            case 'negativo':
                return 'serena-message negative-message';
            case 'alerta':
                return 'serena-message alert-message';
            default:
                return 'serena-message neutral-message';
        }
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="serenaBotCard">
            <div className="card-header">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faRobot} style={{ marginRight: '10px' }} />
                    <span>Serena</span>
                </div>
                <a href="#" style={{ marginLeft: 'auto', color: '#fff' }}>Ver mais</a>
            </div>
            <div className={`card-content ${nivelUsuario === 1 ? 'blurred-content' : ''}`}>
                {nivelUsuario > 1 && mensagens.slice(0, currentMessageIndex).map((mensagem, index) => (
                    <div key={index} className="serena-message-wrapper">
                        <div className="serena-icon" />
                        <div className={getMessageClass(mensagem.tipo)}>
                            <p>{mensagem.texto}</p>
                        </div>
                    </div>
                ))}
                {typing && nivelUsuario > 1 && (
                    <div className="serena-message-wrapper">
                        <div className="serena-icon" />
                        <div className="serena-message neutral-message typing-indicator">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>
                )}
                {nivelUsuario === 1 && (
                    <div className="message-overlay">
                        <p>Para ver esta mensagem claramente, assine um plano.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SerenaBot;
