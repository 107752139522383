import React, { useEffect, useState, useRef } from 'react';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import './css/DespesasPieChart.css';

const DespesasPieChart = ({ userId, selectedDate }) => {
    const [dadosDespesas, setDadosDespesas] = useState([]);
    const chartRef = useRef(null);
    const [nivelUsuario, setNivelUsuario] = useState(1); // Valor padrão como 1
    const navigate = useNavigate();

    useEffect(() => {
        if (!userId || !selectedDate) {
            console.error('User ID ou Data não fornecido');
            return;
        }

        const fetchDespesas = async () => {
            try {
                const response = await fetch(`/api/users/${userId}/despesasPorCategoria?date=${selectedDate}`);
                const data = await response.json();
                if (response.ok) {
                    setDadosDespesas(data.despesasPorCategoria || []);
                } else {
                    console.error('Erro ao buscar as despesas por categoria:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar as despesas por categoria:', error);
            }
        };

        const fetchUserLevel = async () => {
            try {
                const response = await fetch(`/api/users_nivel/${userId}`);
                const data = await response.json();
                if (response.ok) {
                    setNivelUsuario(data.nivel); // Armazena o nível do usuário
                } else {
                    console.error('Erro ao buscar o nível do usuário:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar o nível do usuário:', error);
            }
        };

        fetchUserLevel();
        fetchDespesas();
    }, [userId, selectedDate]);

    useEffect(() => {
        const updateLegendColors = () => {
            const chartInstance = chartRef.current;
            if (chartInstance) {
                const isDarkMode = document.body.classList.contains('dark-mode');
                const fontColor = isDarkMode ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color');

                chartInstance.options.plugins.legend.labels.color = fontColor;
                chartInstance.update();
            }
        };

        const observer = new MutationObserver(() => {
            updateLegendColors();
        });

        observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

        return () => {
            observer.disconnect();
        };
    }, []);

    const montarDadosGrafico = () => {
        return {
            labels: dadosDespesas.map(despesa => despesa.CATEGORIA || 'Sem Categoria'),
            datasets: [
                {
                    data: dadosDespesas.map(despesa => despesa.totalDespesa),
                    backgroundColor: dadosDespesas.map((_, index) => {
                        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
                        return colors[index % colors.length];
                    }),
                    hoverBackgroundColor: dadosDespesas.map((_, index) => {
                        const colors = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];
                        return colors[index % colors.length];
                    }),
                },
            ],
        };
    };

    const handleNavigateToDespesas = () => {
        navigate('/despesas');
    };

    const options = {
        plugins: {
            legend: {
                position: 'left',
                labels: {
                    usePointStyle: true,
                    color: document.body.classList.contains('dark-mode') ? getComputedStyle(document.body).getPropertyValue('--light-color') : getComputedStyle(document.body).getPropertyValue('--dark-color'),
                },
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div className="card primary-inner-card" draggable="true" id="despesasPieChartCard">
            <div className="card-header">
                Despesas por Categoria
                <button onClick={handleNavigateToDespesas} className="btn-ver-despesas">
                    Ver Despesas
                </button>
            </div>
            <div className={`card-content ${nivelUsuario === 1 ? 'blurred-content' : ''}`}>
                <Pie ref={chartRef} data={montarDadosGrafico()} options={options} />
                {nivelUsuario === 1 && (
                    <div className="message-overlay">
                        <p>Para ver este gráfico claramente, assine um plano.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DespesasPieChart;
