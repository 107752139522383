import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; 
import './css/LancamentosAnoCard.css';

const LancamentosAnoCard = ({ selectedDate, userId }) => {
    const [dadosGrafico, setDadosGrafico] = useState({ receitas: [], despesas: [] });
    const cardRef = useRef(null);
    const [showLegend, setShowLegend] = useState(true);

    useEffect(() => {
        if (!userId || !selectedDate) {
            console.error('User ID ou Data não fornecido');
            return;
        }

        const fetchLancamentosAno = async () => {
            try {
                const year = selectedDate.slice(0, 4);
                const response = await fetch(`/api/users/${userId}/lancamentosAno?year=${year}`);
                const data = await response.json();
                if (response.ok) {
                    setDadosGrafico({
                        receitas: data.receitaPorMes,
                        despesas: data.despesaPorMes,
                    });
                } else {
                    console.error('Erro ao buscar lançamentos por ano:', data.message);
                }
            } catch (error) {
                console.error('Erro ao buscar lançamentos por ano:', error);
            }
        };

        fetchLancamentosAno();
    }, [selectedDate, userId]);

    useEffect(() => {
        const handleResize = () => {
            if (cardRef.current) {
                const width = cardRef.current.offsetWidth;
                setShowLegend(width > 500); 
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); 

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const montarDadosGrafico = () => {
        const meses = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const receitasPorMes = Array(12).fill(0);
        const despesasPorMes = Array(12).fill(0);

        dadosGrafico.receitas.forEach(item => {
            const mesIndex = parseInt(item.mes.split('-')[1], 10) - 1;
            receitasPorMes[mesIndex] = item.valor;
        });

        dadosGrafico.despesas.forEach(item => {
            const mesIndex = parseInt(item.mes.split('-')[1], 10) - 1;
            despesasPorMes[mesIndex] = item.valor;
        });

        return {
            labels: meses,
            datasets: [
                {
                    label: 'Receita',
                    data: receitasPorMes,
                    backgroundColor: 'rgba(122, 111, 190, 0.7)', // Cor ajustada para a paleta do sistema
                    borderColor: 'rgba(122, 111, 190, 1)', // Cor sólida correspondente
                    borderWidth: 1,
                },
                {
                    label: 'Despesa',
                    data: despesasPorMes,
                    backgroundColor: 'rgba(217, 90, 111, 0.7)', // Cor ajustada para a paleta do sistema
                    borderColor: 'rgba(217, 90, 111, 1)', // Cor sólida correspondente
                    borderWidth: 1,
                },
            ],
        };
    };

    return (
        <div className="card warning-inner-card card-grafico" draggable="true" id="newCard5" ref={cardRef}>
            <div className="card-header">Lançamentos Ano</div>
            <div className="card-content">
                <Bar 
                    data={montarDadosGrafico()} 
                    options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: showLegend,
                            },
                        },
                    }} 
                />
            </div>
        </div>
    );
};

export default LancamentosAnoCard;
